import RuleSet from './rule-set';


class Router extends RuleSet {

	route(subject, callbackExtraParams = [], paramsTarget = {})	{
		if (this.match(subject, callbackExtraParams)) {
			subject = this.replace(subject, callbackExtraParams, paramsTarget);
		}
		return subject;
	}

}

export default Router;
