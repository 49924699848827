import {ucFirst} from '../../common/utils/string';
import {getScrollTop} from '../../common/utils/get-scroll';
import Context from '../../common/context/context';


class DefaultContext extends Context {

	constructor({name, root, popOnKey = false, outClass = 'covered', coveredByClass = 'coveredBy', isTransitioningAttribute = 'isTransitioning'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.prevScrollPos = 0;
		this.outClass = outClass;
		this.coveredByClass = coveredByClass;
		this.elements = null;
		this.isTransitioningAttribute = isTransitioningAttribute;
	}


	getElements() {
		if (!this.elements) {
			this.elements = this.root.querySelectorAll(this.dataSelector('context', 'default') + ' > *:not(script):not(noscript):not(svg):not(' + this.dataSelector('context') + ')');
		}
		return this.elements;
	}


	switchIn(otherContext) {
		const coveredByClass = this.coveredByClass + ucFirst(otherContext.getName());
		for (const node of this.getElements()) {
			if (node) {
				this.threeStateTransition(node).remove(coveredByClass);
				this.classList(node).remove(this.outClass);
				node.style.removeProperty('top');
			}
		}
		window.scrollTo(0, this.prevScrollPos);
		return otherContext;
	}


	switchOut(otherContext) {
		this.dataAttr(this.root).set(this.isTransitioningAttribute, true);
		const scrollPos = getScrollTop();
		this.prevScrollPos = scrollPos;
		const tops = [];
		for (const node of this.getElements()) {
			if (node) {
				const rect = node.getBoundingClientRect();
				tops.push(rect.top);
			}
		}
		let i = 0;
		const coveredByClass = this.coveredByClass + ucFirst(otherContext.getName());
		for (const node of this.getElements()) {
			if (node) {
				this.threeStateTransition(node).add(coveredByClass);
				this.classList(node).add(this.outClass);
				node.style.top = tops[i] + 'px';
			}
			i++;
		}
		window.scrollTo(0, 0);
		return otherContext;
	}


}


export default DefaultContext;
