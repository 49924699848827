import commonConfig from './common';

import appConfig from '../app/_config';
// import animationConfig from '../animation/_config';
import bookingConfig from '../booking/_config';
import contextConfig from '../context/_config';
import formConfig from '../form/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';
import teaserConfig from '../teaser/_config';
import trackersConfig from '../trackers/_config';
import animationConfig from '../animation/_config';
import imageInfoConfig from '../imageinfo/_config';
import widgetConfig from '../widget/_config';
import gridHelperConfig from '../gridhelper/_config';
import sliderConfig from '../slider/_config';
import editorHeroConfig from '../editorhero/_config';
import profileHeroConfig from '../profilehero/_config';

const config = (di) => {
	commonConfig(di);

	appConfig(di);
	// animationConfig(di);
	bookingConfig(di);
	contextConfig(di);
	formConfig(di);
	menuConfig(di);
	pageConfig(di);
	teaserConfig(di);
	trackersConfig(di);
	animationConfig(di);
	imageInfoConfig(di);
	widgetConfig(di);
	gridHelperConfig(di);
	sliderConfig(di);
	editorHeroConfig(di);
	profileHeroConfig(di);

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	di
		.setValue('baseUrl', baseUrl)
		.setValue('google/mapApiUrl', bodyData.get('googleMapApiUrl', ''))
		.setValue('google/mapApiKey', bodyData.get('googleMapApiKey', ''))
		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
		.setParam('Navigation', 'navigateSameUrl', true)
	;
};


export default config;
