import PageComponent from '../../common/component/page-component';


class ScrollIndicator extends PageComponent {

	constructor({
		root,
		element,
		activeClass = 'active',
		targetAttribute = 'scrollIndicator',
		buttonAttribute = 'scrollIndicatorButton',
	}) {
		super({root: root, element: element});
		this.defaults.activeClass = activeClass;
		this.defaults.targetAttribute = targetAttribute;
		this.defaults.buttonAttribute = buttonAttribute;
		this.observerId = element;
		this.visibleElementButtons = new Set();
		this.lastAktiveElementButtons = null;
	}


	injectIntersectionObservers(intersectionObservers) {
		this.intersectionObservers = intersectionObservers;
	}


	prepare() {
		const data = this.dataAttr().getAll();

		this.targetList = this.root.querySelectorAll(this.dataSelector(data.targetAttribute));
		this.targetMap = new Map();

		if(this.targetList.length > 0) {
			this.intersectionObservers.initObserver(this.observerId, 0, this.onIntersect.bind(this));

			for (let i = 0; i < this.targetList.length; i++) {
				const target = this.targetList[i];
				const button = this.element.querySelector(this.dataSelector(data.buttonAttribute, this.dataAttr(target).get('hash')));

				this.targetMap.set(target, button);
				this.intersectionObservers.observe(this.observerId, target);
			}
		}
	}


	onIntersect(entries) {
		//collect candidates
		for (const entry of entries) {
			const button = this.targetMap.get(entry.target);
			if (entry.isIntersecting) {
				this.visibleElementButtons.add(button);
				this.lastAktiveElementButtons = null;
			} else {
				this.visibleElementButtons.delete(button);
				if (this.visibleElementButtons.size === 0) {
					this.lastAktiveElementButtons = button;
				}
			}
		}

		//activate candidate
		const activeClass = this.dataAttr().get('activeClass');
		if (this.visibleElementButtons.size > 0) {
			let firstIsAktive = false;
			for (const button of this.targetMap.values()) {
				if (this.visibleElementButtons.has(button)) {
					if (!firstIsAktive) {
						firstIsAktive = true;
						this.classList(button).add(activeClass);
					} else {
						this.classList(button).remove(activeClass);
					}
				} else {
					this.classList(button).remove(activeClass);
				}
			}
		} else {
			for (const button of this.targetMap.values()) {
				this.classList(button).toggle(activeClass, button === this.lastAktiveElementButtons);
			}
		}
	}


	clear() {
		if (this.targetList.length > 0) {
			for (const target of this.targetList) {
				this.intersectionObservers.unobserve(this.observerId, target);
			}
		}
		super.clear();
	}


}

export default ScrollIndicator;
