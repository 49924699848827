// needed to interact with google map api
/*eslint-disable piggyback/no-restricted-global-extend*/


class GoogleMapApiLoader {

    constructor({url, apiKey, callbackName = 'onGoogleMapApiReady'}) {
        this.url = (url && url.length ?  url.replace('{{apiKey}}', apiKey).replace('{{callback}}', callbackName) : '');
        this.callbackName = callbackName;
		this.script = null;
		this.promise = null;
		this.promiseResolveTrigger = null;
        this.google = null;
	}


    load() {
		if (!this.url.length) {
			throw Error('Google Map lib url is empty');
		}
		if (this.promise === null) {
			this.promise = new Promise((resolve) => {
				this.promiseResolveTrigger = resolve;
				window[this.callbackName] = this.onGoogleMapApiReady.bind(this);
				this.script = document.createElement('script');
				this.script.async = true;
				this.script.defer = true;
				this.script.setAttribute('src', this.url);
				document.body.appendChild(this.script);
			});
		}
		return this.promise;
	}


    onGoogleMapApiReady() {
		this.google = window.google;
		this.promiseResolveTrigger(this.google);
    }

}


export default GoogleMapApiLoader;
