import {gsap} from 'gsap';
import PageComponent from '../../common/component/page-component';

class NewMainMenu extends PageComponent {
	constructor({
			root,
			element,
			navIconsData = 'navIcons',
			navButtonData = 'menuToggler',
			logoData = 'navsLogo',
			navData = 'nav',
			menusWrapperData = 'allMenu',
			menuArrowBoxData = 'menuArrow',
			menuItemData = 'menuItem',
			menuArrowLineData = 'arrowLine',
			groupData = 'navGroup',
			menuData = 'navMenu',
			contentData = 'navContent',
			navsWrapperData = 'navsWrapper',
		}) {
		super({root: root, element: element});
		this.navIconsData = navIconsData;
		this.navButtonData = navButtonData;
		this.logoData = logoData;
		this.navData = navData;
		this.menuItemData = menuItemData;
		this.menusWrapperData = menusWrapperData;
		this.menuArrowBoxData = menuArrowBoxData;
		this.menuArrowLineData = menuArrowLineData;
		this.groupData = groupData;
		this.menuData = menuData;
		this.contentData = contentData;
		this.navsWrapperData = navsWrapperData;
	}

	prepare() {
		/*
		this.navButton = document.querySelector(this.dataSelector(this.navButtonData));
		this.logo = this.element.querySelector(this.dataSelector(this.logoData));
		this.groupItems = this.element.querySelectorAll(this.dataSelector(this.groupData));
		this.menuItems = this.element.querySelectorAll(this.dataSelector(this.menuItemData));
		this.navs = this.element.querySelectorAll(this.dataSelector(this.navData));
		this.mainNavLine = this.navs[0].lastElementChild;
		this.menusWrapper = document.querySelector(this.dataSelector(this.menusWrapperData));
		this.navIconsBox = this.navButton.querySelector(this.dataSelector(this.navIconsData));
		this.groups = gsap.utils.toArray(this.element.querySelectorAll(this.dataSelector(this.groupData)));
		this.menus = gsap.utils.toArray(this.element.querySelectorAll(this.dataSelector(this.menuData)));
		this.navsWrapper = this.element.querySelector(this.dataSelector(this.navsWrapperData));
		this.contents = this.element.querySelectorAll(this.dataSelector(this.contentData));
		this.menuArrowBoxes = gsap.utils.toArray(this.element.querySelectorAll(this.dataSelector(this.menuArrowBoxData)));
		this.menuArrowLines = gsap.utils.toArray(this.element.querySelectorAll(this.dataSelector(this.menuArrowLineData)));
		this.openNavIcon = this.navIconsBox.firstElementChild;
		this.closeNavIcon = this.navIconsBox.lastElementChild;
		this.element.style.paddingTop = 0;
		this.element.style.paddingBottom = 0;
		this.navs[0].style.marginBottom = 0;
		this.logo.style.marginBottom = 0;
		this.animations = [];
		this.menuColor = '#ff6955';

		this.tl = gsap.timeline();

		this.createNavAnimation();

		window.addEventListener('resize', this.onResize.bind(this));

		this.navButton.addEventListener('click', (e) => {
			e.preventDefault();
			if(window.innerWidth >= 768) {
				this.setNestedMenuProps();
			}
			return this.tl.reversed() ? this.tl.play() : this.tl.reverse();
		});

		this.onResize();

		//this.groups.forEach(group => this.createAnimation(group));

		this.menus.forEach((menu) => {
			this.toggleEventsMenu(menu);
		});
		*/
	}

	showArrow(menu, isShow = false) {
		const arrow = menu.querySelector(this.dataSelector(this.menuArrowBoxData));
		const isLink = menu.firstElementChild.matches('.tni-newMenu__link');

		if(!isLink && window.innerWidth >= 768) {
			if(isShow) {
				arrow.style.opacity = 1;
			} else {
				arrow.style.opacity = 0;
			}
		}
	}

	changeMenuColor(menu, isChanged = false) {
		if(isChanged) {
			menu.style.color = this.menuColor;
		} else {
			menu.style.color = 'inherit';
		}
	}

	toggleArrowAndMenuColor(menu, isChanged = false) {
		if(isChanged) {
			this.changeMenuColor(menu, true);
			//this.showArrow(menu, true);
		} else {
			this.changeMenuColor(menu);
			//this.showArrow(menu);
		}
	}

	toggleEventsMenu(menu) {
		const isLink = menu.firstElementChild.matches('.tni-newMenu__link');
		const box = menu.nextElementSibling;

		menu.addEventListener('click', (e) => {
			e.stopPropagation();

			if(window.innerWidth >= 768) {
				const selectedReversedState = menu.animation.reversed();
				if(selectedReversedState) this.toggleArrowAndMenuColor(menu);
			}

			this.toggleMenuAnimation(menu);
		});

		if(window.innerWidth >= 768) {
			menu.addEventListener('mouseleave', (e) => {
				e.preventDefault();

				if(!isLink && window.innerWidth >= 768) {
					if(box.style.opacity === '1') {
						this.toggleArrowAndMenuColor(menu, true);
					} else {
						this.toggleArrowAndMenuColor(menu);
					}
				}
			});
			menu.addEventListener('mouseenter', (e) => {
				e.preventDefault();
				if(!isLink && window.innerWidth >= 768) {
					this.toggleArrowAndMenuColor(menu, true);
				}
			});
		}
	}

	toggleMenuAnimation(menu) {
		const selectedReversedState = menu.animation.reversed();
		this.animations.forEach(animation => animation.reverse());
		menu.animation.reversed(!selectedReversedState);
	}

	createAnimation(element) {
    const menu = element.querySelector(this.dataSelector(this.menuData));
		const box  = element.querySelector(this.dataSelector(this.contentData));
		const arrow = menu.querySelector(this.dataSelector(this.menuArrowBoxData));
		const tl = gsap.timeline();

		if(window.innerWidth >= 768) {
			const nestedMenu  = box.firstElementChild;
			const desktopBoxProps = {
				opacity: 1,
				xPercent: 100,
				ease: 'power1.inOut',
				duration: 0.5
			};
			const desktopArrowProps = {
				opacity: 1,
				width: '100%',
				ease: 'power1.inOut'
			};
			const desktopLineProps = {
				opacity: 1,
				duration: 0.03
			};
			const nestedMenuProps = {
				opacity: 1,
				display: 'block',
				xPercent: 0,
				duration: 0.5,
				ease: 'power1.inOut'
			};
			const menuProps = {
				duration: 0.3,
				ease: 'power1.inOut',
				color: this.menuColor
			};

			if(box) {
				tl.paused(true);
				let desktopTween;

				gsap.set([box, nestedMenu], {
					opacity: 0,
				});
				gsap.set(arrow, {
					width: 6
				});
				gsap.set([box, nestedMenu], {
					xPercent: 0
				});
				gsap.set(this.mainNavLine, {opacity: 0});

				if(arrow) {
					desktopTween = tl
					.to(box, desktopBoxProps)
					.to(arrow, desktopArrowProps, 0)
					.to(this.mainNavLine, desktopLineProps)
					.to(menu, menuProps, 0)
					.to(nestedMenu, nestedMenuProps, 0);
				} else {
					desktopTween = tl.to(box, desktopBoxProps);
				}

				menu.animation = desktopTween;
				this.animations.push(desktopTween);
			} else {
				return;
			}
		} else {
			// const iconBox = element.querySelector(this.dataSelector(this.menuIconBoxData));
			const mobileBoxProps = {
				height: 0,
				duration: 0.5,
				ease: 'power2.inOut'
			};
			const mobileIconProps = {
				rotation: -90,
				transformOrigin: '50% 50%',
				duration: 0.5,
				ease: 'power2.inOut'
			};

			if(box) {
				gsap.set(box, {height: 'auto'});
				tl.paused(true).reversed(true);
				let mobileTween;

				if(arrow) {
					mobileTween = tl.from(box, mobileBoxProps).to(arrow, mobileIconProps, 0);
				} else {
					mobileTween = tl.from(box, mobileBoxProps);
				}

				menu.animation = mobileTween;
				this.animations.push(mobileTween);
			} else {
				return;
			}
		}
	}

	checkVerticalSpacing() {
		this.windowVerticalSpacing = (window.innerHeight - this.logo.clientHeight - (this.navs[0].clientHeight + this.navs[1].clientHeight))/2;

		if (window.innerWidth >= 1280) {
			this.element.style.paddingTop = `${this.windowVerticalSpacing}px`;
			this.element.style.paddingBottom = `${this.windowVerticalSpacing}px`;
			this.navs[0].style.marginBottom = 0;
			this.logo.style.marginBottom = 0;
		} else if(window.innerWidth >= 768 && window.innerWidth < 1280) {
			this.navs[0].style.marginBottom = `${this.windowVerticalSpacing}px`;
			this.logo.style.marginBottom = `${this.windowVerticalSpacing}px`;
			this.element.style.paddingTop = 0;
			this.element.style.paddingBottom = 0;
		}
	}

	setNestedMenuProps() {
		this.contents.forEach(content => {
			const contentChild = content.firstElementChild;
			const contentParent = content.parentNode;

			contentChild.style.top = `-${contentParent.offsetTop}px`;
		});
	}

	createNavAnimation() {
		this.tl.paused(true).reversed(true);

		this.tl
		.set(this.menusWrapper, {display: 'none'})
		.set([this.menusWrapper, this.element, this.navs[0], this.navs[1], this.logo, this.menuItems], {opacity: 0})
		.to(this.menusWrapper, {
			opacity: 1,
			display: 'block',
			zIndex: '3',
			height: '100%',
			ease: 'power2.inOut',
		})
		.to(this.element, {opacity: 1, duration: 0.1}, 0)
		.to([this.navs[0], this.navs[1]], {
			opacity: 1
		}, 0)
		.set([this.logo, this.menuItems], {y: -100})
		.to(this.logo, {
			duration: 0.3,
			opacity: 1,
			y: 0
		})
		.to(this.menuItems, {
			y: 0,
			stagger: {
				each: 0.03,
				ease: 'power2.inOut',
			},
			opacity: 1
		})
		.to(this.openNavIcon, {
			opacity: 0,
			display: 'none',
			duration: 0.3
		})
		.to(this.closeNavIcon, {
			opacity: 1,
			display: 'block',
			duration: 0.3
		}, '-=0.2');
	}

	onResize() {
		if(window.innerWidth >= 768) {
			this.checkVerticalSpacing();
			this.setNestedMenuProps();
		}
	}
}

export default NewMainMenu;
