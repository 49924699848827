import FormNewsletter from './form-newsletter';
import TickerText from './ticker';


export default (di) => {
	di
		.setType({type: FormNewsletter, name: 'FormNewsletter', parent: 'FormSubmitPreCondition'})
	;
	di
		.setType({type: TickerText, name: 'Ticker', parent: 'PageComponent'})
	;
};
