import trim from 'locutus/php/strings/trim';
import ltrim from 'locutus/php/strings/ltrim';
import rtrim from 'locutus/php/strings/rtrim';
import ucfirst from 'locutus/php/strings/ucfirst';
import lcfirst from 'locutus/php/strings/lcfirst';


export {trim, ltrim as leftTrim, rtrim as rightTrim, ucfirst as ucFirst, lcfirst as lcFirst};


// export function ucFirst(value) {
//     return value.charAt(0).toUpperCase() + value.substr(1);
// }
//
//
export function decamelize(value, separator = '-') {
    return value.replace(/([a-z0-9]+)([A-Z]+)/g, '$1' + separator + '$2').toLowerCase();
}


export function camelize(value, setUcFirst = false) {
    value = value.replace(/[-_]+([a-z0-9])/gi, (all, letter) => (letter.toUpperCase()));
    return (setUcFirst? ucfirst(value) : value);
}


export function trimUrl(url) {
	return rtrim(url, '/');
}
