import NewTextSlider from './new-text-slider';
import NewScaleImage from './new-scale-image';
import NewSlidingMenu from './new-sliding-menu';

export default (di) => {
	di
		.setType({type: NewTextSlider, name: 'NewTextSlider', parent: 'PageComponent'})
	;
	di
		.setType({type: NewScaleImage, name: 'NewScaleImage', parent: 'PageComponent'})
	;
	di
		.setType({type: NewSlidingMenu, name: 'NewSlidingMenu', parent: 'PageComponent'})
	;
};
