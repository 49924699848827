import namespace from 'consts:namespace';
import Container from './common/di/container';
import config from './app/config/config';

export default async () => {
	const container = new Container();
	container.setValue('dom/dataAttrPrefix', namespace + '-');
	container.setValue('dom/classPrefix', namespace + '-js-');
	container.setValue('dom/baseClassPrefix', namespace + '-');
	await config(container);

	const app = container.get('app');

	app.init().then(() => app.execute());
};
