import Rule from './rule';
import RuleSet from './rule-set';
import Router from './router';


export default (di) => {
	di
		.setType({type: Rule, name: 'Rule'})
		.setType({type: RuleSet, name: 'RuleSet', params: {
			ruleFactory: di.newFactory('Rule'),
			ruleSetFactory: di.newFactory('RuleSet')
		}})
		.setType({type: Router, name: 'Router', parent: 'RuleSet'})
		.set('router/pageTransitionRouter', di.lazyNew('Router'))
		.set('router/pageLoaderRouter', di.lazyNew('Router'))
	;
};
