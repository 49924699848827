import picturefill from 'picturefill';
import objectFitImages from 'object-fit-images';
import imagesLoaded from 'imagesloaded';
import {waitCondition} from '../../common/utils/wait';
import PageComponent from '../component/page-component';


const EMPTY_IMG_PLACEHOLDER = 'data:image/gif;base64,R0lGODlhAQABAAAAADs=';

class AsyncPicture extends PageComponent {


	constructor({root, element, loadedClass = 'loaded', autoload = true}) {
		super({root: root, element: element});
		this.defaults.autoload = autoload;
		this.defaults.loadedClass = loadedClass;
		this.isImg = null; // will be a boolean after prepare
		this.loading = false;
	}


	prepare() {
		this.openAsyncEvent('load');
		this.isImg = this.element.tagName.toLowerCase() === 'img';
		this.img = this.isImg ? this.element : this.element.querySelector('img');
		if (this.dataAttr().get('autoload') === true) {
			this.load();
		}
	}


	load() {
		if (!this.loading) {
			this.loading = true;
			const nodes = this.isImg ? [this.element] : this.element.querySelectorAll(this.dataSelector('srcset'));
			for (const node of nodes) {
				const dataAttr = this.dataAttr(node);
				if (dataAttr.has('srcset')) {
					node.setAttribute('srcset', dataAttr.get('srcset'));
					dataAttr.remove('srcset');
					if (this.isImg && dataAttr.has('sizes')) {
						node.setAttribute('sizes', dataAttr.get('sizes'));
						dataAttr.remove('sizes');
					}
				}
			}
			picturefill({
				reevaluate: true,
				elements: [this.img]
			});

			objectFitImages(this.img);
			// IE does not support currentSrc
			const currentSrcSupported = this.img.currentSrc !== undefined;
			const condition = currentSrcSupported ?
				() => (this.img.currentSrc && this.img.currentSrc.length && this.img.currentSrc !== EMPTY_IMG_PLACEHOLDER) :
				() => (this.img.src && this.img.src.length && this.img.src !== EMPTY_IMG_PLACEHOLDER)
			;
			waitCondition(condition).then(() => {
				const img = new Image();
				img.src = currentSrcSupported ? this.img.currentSrc : this.img.src;
				imagesLoaded([img], () => {
					this.closeAsyncEvent('load');
					this.classList(this.element).add(this.dataAttr().get('loadedClass'));

					if (this.element.parentNode.dataset.type === 'image') {
						this.classList(this.element.parentNode).add(this.dataAttr().get('loadedClass'));
					}

					if (this.element.parentNode.parentNode.dataset.type === 'image') {
						this.classList(this.element.parentNode.parentNode).add(this.dataAttr().get('loadedClass'));
					}
					if (this.element.parentNode.parentNode.parentNode.dataset.type === 'image') {
						this.classList(this.element.parentNode.parentNode.parentNode).add(this.dataAttr().get('loadedClass'));
					}

					this.events.trigger(this.element, 'picture:load', {component: this});
				});
			});
		}
		return this.on('load');
	}


	getImg() {
		return this.img;
	}

}

export default AsyncPicture;
