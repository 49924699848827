import domMixin from '../../common/dom/dom-mixin';
import {getScrollTop} from '../../common/utils/get-scroll';
import {getDocumentHeight, getViewportHeight} from '../../common/utils/size';


class MatomoCustomEventTracker extends domMixin() {
	constructor({
		root,
		element,
		trackClickAttribute = 'trackClick',
		trackShareAttribute = 'trackShare'
	}) {
		super({root: root, element: element});

		this.trackClickAttribute = trackClickAttribute;
		this.trackShareAttribute = trackShareAttribute;
		this.scrollableHeight = 0;
		this.scrollFuzz = 0.05;
		this.formattedPos = '';
	}


	injectTracker(tracker) {
		this.tracker = tracker;
	}


	init() {
		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.scrollListener = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.toggelListener = this.events.on(document.body, 'content:resize', this.onToggle.bind(this));
		this.clickListener = this.events.on(this.dataSelector(this.trackClickAttribute), 'click', this.onClick.bind(this));
		this.clickListener = this.events.on(this.dataSelector(this.trackShareAttribute), 'click', this.onShare.bind(this));
		this.slideListener = this.events.on(document.body, 'slideshow:afterswitch', this.onSlide.bind(this));
		this.contextListener = this.events.on(document.body, 'context:switchcomplete', this.onContext.bind(this));
		this.onResize(null);
	}


	destroy() {
		this.resizeListener.destroy();
		this.scrollListener.destroy();
		this.toggelListener.destroy();
		this.clickListener.destroy();
		this.slideListener.destroy();
		this.contextListener.destroy();
	}


	onContext(event) {
		if (event.detail.currentContext.name === 'caption') {
			this.tracker.trackEvent('navigation', 'click', 'caption');
		}
	}


	onSlide(event) {
		let type;
		if (event.detail.direction === null) {
			type = 'go-to';
		} else if (event.detail.direction === 1) {
			type = 'next';
		} else if (event.detail.direction === -1) {
			type = 'prev';
		}
		this.tracker.trackEvent('slideshow', 'change', type);
	}


	onClick(event, target) {
		this.tracker.trackEvent('navigation', 'click', this.dataAttr(target).get(this.trackClickAttribute));
	}


	onShare(event, target) {
		this.tracker.trackEvent('navigation', 'share', this.dataAttr(target).get(this.trackShareAttribute));
	}


	onToggle(event, target) {
		this.tracker.trackEvent('content', 'toggler',
			event.detail.collapsed ? 'open' : 'closed'
		);
	}


	onResize(event) {
		this.scrollableHeight = getDocumentHeight() - getViewportHeight();
	}


	onScroll(event) {
		this.scrollableHeight = getDocumentHeight() - getViewportHeight();
		const scrolledHeight = getScrollTop() / this.scrollableHeight;

		// only fire once
		// reset counter when (page:XXX)
		for (const pos of [0.5, 0.75, 1]) {
			if (scrolledHeight >= pos - this.scrollFuzz && scrolledHeight <= pos + this.scrollFuzz) {
				const formatted = (pos * 100) + '%';
				if (this.formattedPos !== formatted) {
					this.formattedPos = formatted;
					this.tracker.trackEvent('page', 'scroll-position', this.formattedPos);
				}
			}
		}
	}

}

export default MatomoCustomEventTracker;
