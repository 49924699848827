import VideoPlayerDriver from '../media/video-player-driver';


/**
 * Vimeo Player Driver (used internally by VideoPlayer Class)
 * Additional info on params available only for Vimeo:
 * https://developer.vimeo.com/player/sdk/embed
 *
 * @class VimeoVideoPlayerDriver
 * @extends {VideoPlayerDriver}
 */
class VimeoVideoPlayerDriver extends VideoPlayerDriver {

	createInternalPlayer(api) {
		this.vimeo = api;
		const params = Object.assign({}, this.params, {
			url: this.url,
			dnt: true
		});
		this.status = {
			buffering: false,
			ended: false,
			paused: false,
			playing: false
		};
		this.muted = this.params.muted;
		this.volumeValue = null;
		return new Promise((resolve) => {
			this.player = new this.vimeo.Player(this.element, params);
			this.player.on('bufferend', this.onBufferEnd.bind(this));
			this.player.on('bufferstart', this.onBufferStart.bind(this));
			this.player.on('ended', this.onEnded.bind(this));
			this.player.on('error', this.onError.bind(this));
			this.player.on('pause', this.onPause.bind(this));
			this.player.on('play', this.onPlay.bind(this));
			this.player.on('playbackratechange', this.onPlaybackRateChange.bind(this));
			this.player.ready().then(() => {
				// the start point appended to the URL does not work using the API, so we need to
				// manually seek to the desired position as soon as the player is ready
				if ('start' in this.params && String(this.params.start) !== '0') {
					return this.setTime(this.params.start);
				}
				return Promise.resolve();
			}).then(() => resolve());
		});
	}


	destroy() {
		if (this.player) {
			// removes the iframe from the dom
			this.player.destroy();
		}
	}


	onBufferEnd() {
		this.status.buffering = false;
		this.controller.triggerEvent(this.controller.eventNames.bufferend);
	}


	onBufferStart() {
		this.status.buffering = true;
		this.controller.triggerEvent(this.controller.eventNames.bufferstart);
	}


	onEnded(data) {
		this.status.ended = true;
		this.controller.triggerEvent(this.controller.eventNames.ended, {
			duration: data.duration,
			progress: data.percent,
			time: data.seconds
		});
	}


	onError(data) {
		// Example:
		// {
		// 	 "message": "#984220 does not meet the minimum contrast ratio. We recommend using brighter colors. (You could try #D35E30 instead.)
		// 	 "method": "setColor",
		// 	 "name": "ContrastError"
		// }
		this.controller.triggerEvent(this.controller.eventNames.error, data);
	}


	onPause(data) {
		this.status.paused = true;
		this.status.playing = false;
		this.controller.triggerEvent(this.controller.eventNames.paused, {
			duration: data.duration,
			progress: data.percent,
			time: data.seconds
		});
	}


	onPlay(data) {
		this.status.ended = false;
		this.status.paused = false;
		this.status.playing = true;
		this.controller.triggerEvent(this.controller.eventNames.playing, {
			duration: data.duration,
			progress: data.percent,
			time: data.seconds
		});
	}


	onPlaybackRateChange(data) {
		this.controller.triggerEvent(this.controller.eventNames.speedchange, {speed: data.playbackRate});
	}


	play() {
		return this.player.play();
	}


	pause() {
		return this.player.pause();
	}


	mute() {
		if (!this.muted) {
			return this.getVolume().then((volume) => {
				this.muted = true;
				this.volumeValue = volume;
				return this.player.setVolume(0);
			});
		}
		return Promise.resolve();
	}


	unMute() {
		if (this.muted) {
			return this.player.setVolume(this.volumeValue).then(() => {
				this.muted = false;
			});
		}
		return Promise.resolve();
	}


	getDuration() {
		return this.player.getDuration();
	}


	// Vimeo supports values in the range [0.5, 2]
	getSpeed() {
		return this.player.getPlaybackRate();
	}


	getTime() {
		return this.player.getCurrentTime();
	}


	/**
	 * @returns {Promise} Resolved with size value in pixels
	 */
	getVideoHeight() {
		return this.player.getVideoHeight();
	}


	/**
	 * @returns {Promise} Resolved with size value in pixels
	 */
	getVideoWidth() {
		return this.player.getVideoWidth();
	}



	/**
	 * NOTE: Most mobile devices don't support a volume level independent of the system volume. In these cases, this method always returns 1.
	 *
	 * @returns {Promise} Resolved with volume value in the range [0, 1]
	 */
	getVolume() {
		if (this.muted) {
			return Promise.resolve(this.volumeValue);
		}

		return this.player.getVolume();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isBuffering() {
		return Promise.resolve(this.player ? this.status.buffering: false);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPaused() {
		return (this.player ? this.player.getPaused() : Promise.resolve(true));
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPlaying() {
		return Promise.resolve(this.player ? this.status.playing : false);
		// return this.playerdriver.isPlaying();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isEnded() {
		return (this.player ? this.player.getEnded() : Promise.resolve(false));
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isMuted() {
		return (this.player ? this.player.getVolume().then((volume) => volume === 0) : Promise.resolve(this.muted));
	}


	/**
	 * NOTE: available only to members with a Vimeo PRO subscription or higher
	 *
	 * @param {Number} speed: values in the range [0.5, 2]
	 * @returns {Promise} resolve after the change
	 */
	setSpeed(speed) {
		return this.driver.setPlaybackRate(speed);
	}


	/**
	 * @param {Number|String} time: new position. Accepted formats: 190 (seconds) | 3:10 | 3m10s | 3m | 10s
	 * @returns {Promise} It resolves after the seek is completed, returning the new time
	 */
	setTime(time) {
		return this.player.setCurrentTime(this.toSeconds(time));
	}


	/**
	 * @param {Number} volume: value in the range [0, 1]
	 * @returns {Promise} resolves eturning the new volume value
	 */
	setVolume(volume) {
		if (volume > 0 && this.muted) {
			this.muted = false;
		}
		return this.player.setVolume(volume).then((newVolume) => {
			this.volumeValue = newVolume;
			return newVolume;
		});
	}

}


export default VimeoVideoPlayerDriver;
