import PageComponent from '../component/page-component';
import componentConstants from '../component/_constants';


const eventNames = {
	bufferend: 'video:bufferend',
	bufferstart: 'video:bufferstart',
	ended: 'video:ended',
	error: 'video:error',
	paused: 'video:paused',
	playing: 'video:playing',
	speedchange: 'video:speedchange',
};


/**
 * VideoPlayer
 *
 * Provide a unified interface abstracting the specific API used, delegating the operations to a specific player driver.
 * Most method returns a Promise.
 *
 * Currently supported drivers are:
 * - Youtube
 * - Vimeo
 */
class VideoPlayer extends PageComponent {

	constructor({
		element,
		root,
		autoload = 'lazy',
		pauseOutOfContext = true,
		embedAttribute = 'embed',
		defaultVideoParams = {
			autoplay: false,
			controls: true,
			loop: false,
			muted: false,
			playsinline: true,
			start: 0			// sec.
		}
	} = {}) {
		super({element: element, root: root});
		this.embedAttribute = embedAttribute;
		this.eventNames = eventNames;
		this.defaults[componentConstants.autoloadAttribute] = autoload;
		this.defaults.pauseOutOfContext = pauseOutOfContext;
		Object.assign(this.defaults, defaultVideoParams);

		this.videoPlayerDriverFactory = null;
		this.driver = null;
		this.loading = false;
		this.wasPlaying = false;
	}


	injectVideoPlayerDriverFactory(videoPlayerDriverFactory) {
		this.videoPlayerDriverFactory = videoPlayerDriverFactory;
	}


	prepare() {
		this.pauseOutOfContext = this.dataAttr().get('pauseOutOfContext');
		this.openAsyncEvent('load');
		this.embed = this.element.querySelector(this.dataSelector(this.embedAttribute));
		const url = this.dataAttr().get('url');
		const params = this.dataAttr().getAll();
		delete params[componentConstants.componentAttribute];
		delete params[componentConstants.autoloadAttribute];
		this.driver = this.videoPlayerDriverFactory.getDriverByUrl(this, this.embed, url, params);
		if (!this.driver) {
			throw Error('No VideoDriver found for url ' + url);
		}
		if (this.dataAttr().get(componentConstants.autoloadAttribute) === true) {
			this.load();
		}
	}


	clear() {
		if (this.driver) {
			this.driver.destroy();
			this.driver = null;
		}
	}


	/**
	 * If the player is playing before its context get deactivated, it restores the playing when it is reactivated
	 *
	 */
	start() {
		if (this.pauseOutOfContext && this.wasPlaying) {
			this.play();
		}
	}


	/**
	 * Pause the eventual playing when the player context get deactivated
	 *
	 */
	stop() {
		if (this.pauseOutOfContext) {
			this.wasPlaying = false;
			this.isPlaying().then((isPlaying) => {
				if (isPlaying) {
					this.wasPlaying = isPlaying;
					this.pause();
				}
			});
		}
	}


	/**
	 * load the video with the eventual required API
	 *
	 * @returns {Promise} async method
	 */
	load() {
		console.log('load video', this.driver);
		if (!this.loading) {
			this.loading = true;
			this.driver.load().then(() => {
				this.closeAsyncEvent('load');
				// try to enforce the autoplay in case the param passed to the specific API was not enough
				if (this.dataAttr().get('autoplay')) {
					this.play();
				}
			});
		}
		return this.on('load');
	}


	/**
	 * @returns {Promise} async method
	 */
	play() {
		return this.on('load').then(() => this.driver.play());
	}


	/**
	 * @returns {Promise} async method
	 */
	pause() {
		return this.on('load').then(() => this.driver.pause());
	}


	/**
	 * @returns {Promise} async method
	 */
	mute() {
		return this.on('load').then(() => this.driver.mute());
	}


	/**
	 * @returns {Promise} async method
	 */
	unMute() {
		return this.on('load').then(() => this.driver.unMute());
	}


	/**
	 * @returns {Promise} Resolved with duration expressed in seconds
	 */
	getDuration() {
		return this.on('load').then(() => this.driver.getDuration());
	}


	/**
	 * @returns {Promise} Resolved with progress value, a percent in the range [0, 1]
	 */
	getProgress() {
		return Promise.all([
			this.getTime(),
			this.getDuration()
		]).then((values) => {
			const time = values[0];
			const duration = values[1];
			if (!duration) {
				return 0;
			}
			return time / duration;
		});
	}


	/**
	 * @returns {Promise} resolved with speed value, a factor like 1 = normal speed, 0.5 half speed, and so on
	 */
	getSpeed() {
		return this.on('load').then(() => this.driver.getSpeed());
	}


	/**
	 * @returns {Promise} Resolved with seconds value, indicating the current time position
	 */
	getTime() {
		return this.on('load').then(() => this.driver.getTime());
	}


	/**
	 * Not available in all the drivers
	 * @returns {Promise} Resolved with size value in pixels, 0 if not available
	 */
	getVideoHeight() {
		return this.on('load').then(() => this.driver.getVideoHeight());
	}


	/**
	 * Not available in all the drivers
	 * @returns {Promise} Resolved with size value in pixels, 0 if not available
	 */
	getVideoWidth() {
		return this.on('load').then(() => this.driver.getVideoWidth());
	}


	/**
	 * @returns {Promise} Resolved with volume value, in the range [0, 1]. It can return a positive number even if the player is muted
	 */
	getVolume() {
		return this.on('load').then(() => this.driver.getVolume());
	}


	/**
	 * Buffering means the video player is loading data needed to play
	 *
	 * @returns {Promise} Resolved with a boolean
	 */
	isBuffering() {
		return this.driver.isBuffering();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPaused() {
		return this.driver.isPaused();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPlaying() {
		return this.driver.isPlaying();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isEnded() {
		return this.driver.isEnded();
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isMuted() {
		return this.driver.isMuted();
	}


	/**
	 * @param {Number} progress: a percent in the range [0, 1]
	 * @returns {Promise} resolved with the final time position in seconds
	 */
	setProgress(progress) {
		return this.getDuration().then((duration) =>
			this.setTime(duration * progress)
		);
	}


	/**
	 * @param {Number} speed: not all drivers support the same range of values.
	 * @returns {Promise} Not all drivers resolve after success, some may resolve immediately
	 */
	setSpeed(speed) {
		return this.on('load').then(() => this.driver.setSpeed(speed));
	}


	/**
	 * @param {Number|String} time: new position. Accepted formats: 190 (seconds) | 3:10 | 3m10s | 3m | 10s
	 * @returns {Promise} Not all drivers resolve after the seek is completed, some may resolve immediately
	 */
	setTime(time) {
		return this.on('load').then(() => this.driver.setTime(time));
	}


	/**
	 * @param {Number} volume: value in the range [0, 1]
	 * @returns {Promise} Not all drivers resolve after the change, some may resolve immediately
	 */
	setVolume(volume) {
		return this.on('load').then(() => this.driver.setVolume(volume));
	}


	/**
	 * @returns {VideoPlayerDriver} the actual driver object
	 */
	getDriver() {
		return this.driver;
	}


	/**
	 * @returns {Object} the actual player provided by the specific API, if already created by the driver
	 */
	getInternalPlayer() {
		return this.driver.getInternalPlayer();
	}


	/**
	 * Shortcut to raise an event
	 *
	 * @param {String} name: the event name, as defined in eventNames object
	 * @param {Object} [data]: eventual values attached to the event.detail property
	 */
	triggerEvent(name, data = {}) {
		this.events.trigger(this.element, name, data);
	}

}


export default VideoPlayer;
