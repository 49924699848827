import Sticky from 'sticky-js';
import PageComponent from '../../common/component/page-component';


class EditorHero extends PageComponent {
	constructor({
		root,
		element,
		altText = 'altText',
		wrapperImageEditor = 'wrapperImageEditor',
		isDestroyed = false,
		windowInnerWidth,
		sticky
	}) {
		super({root: root, element: element});
		this.altText = altText;
        this.wrapperImageEditor = wrapperImageEditor;
		this.sticky = sticky;
		this.isDestroyed = isDestroyed;
		this.windowInnerWidth = windowInnerWidth;
	}

	prepare() {
		this.altText = this.element.querySelector(this.dataSelector(this.altText));
		this.wrapperImageEditor = this.element.querySelector(this.dataSelector(this.wrapperImageEditor));
		this.sticky = new Sticky('.tni-stickyWrapper');
		window.addEventListener('resize', this.onResize.bind(this));
		this.onResize();
    }

	onResize() {
		if (this.wrapperImageEditor) {
			if(document.documentElement.clientWidth < 768 && !this.isDestroyed) {
				this.sticky.destroy();
				this.isDestroyed = true;
			} else {
				if (this.isDestroyed) {
					this.sticky = new Sticky('.tni-stickyWrapper');
					this.sticky.update();
				}
				this.sticky.update();
			}
			this.windowInnerWidth = window.innerWidth;
			// if(this.windowInnerWidth > 1679 && this.windowInnerWidth < 1920) {
			// 	const elementWidth = this.element.offsetWidth;
			// 	if (!this.wrapperImageEditor.classList.contains('tni-newEditorialHero__mediaWrapper--narrow')) {
			// 		this.wrapperImageEditor.setAttribute('style', `margin-left: -${(this.windowInnerWidth - elementWidth) / 2}px`);
			// 		this.wrapperImageEditor.style.width = `${this.wrapperImageEditor.offsetWidth + ((this.windowInnerWidth - elementWidth) / 2)}px`;
			// 	}
			// } else {
			// 	this.wrapperImageEditor.removeAttribute('style');
			// }
			// if(this.altText) {
			// 	const elementWidth = this.element.offsetWidth;
			// 	if (this.windowInnerWidth > 1279 && this.windowInnerWidth < 1920 ) {
			// 		this.altText.setAttribute('style', `padding-left: ${((this.windowInnerWidth - elementWidth) / 2)}px`);
			// 	} else {
			// 		this.altText.removeAttribute('style');
			// 	}

			// }
		}
	}
}

export default EditorHero;
