import PageComponent from '../../common/component/page-component';

class Loader extends PageComponent {
	constructor({
		root,
		element,
		hiddenClass = 'hidden'
	}) {
		super({root: root, element: element});
		this.hiddenClass = hiddenClass;
	}

	playAnimation() {
		return this.classList(this.element).remove(this.hiddenClass);
	}

	removeAnimation() {
		return this.classList(this.element).add(this.hiddenClass);
	}
}

export default Loader;
