import PageComponent from '../component/page-component';


class SlideshowNavigation extends PageComponent {
	constructor({
		root,
		element,
		navigationAttribute = 'goTo',
		handleSwipeAttribute = 'handleSwipe',
		handleSlideClickAttribute = 'handleSlideClick',
		currentClass = 'current',
		stopPropagation = true,
		capture = true,
		handleSwipe = true,
		handleSlideClick = false,
		swipeTimeoutBeforeClick = 100 // ms
	}) {
		super({root: root, element: element});
		this.navigationAttribute = navigationAttribute;
		this.defaults.currentClass = currentClass;
		this.defaults.handleSwipeAttribute = handleSwipeAttribute;
		this.defaults.handleSlideClickAttribute = handleSlideClickAttribute;
		this.defaults.stopPropagation = stopPropagation;
		this.defaults.capture = capture;
		this.defaults.handleSwipe = handleSwipe;
		this.defaults.handleSlideClick = handleSlideClick;
		this.defaults.swipeTimeoutBeforeClick = swipeTimeoutBeforeClick;

		this.swipeTimeout = null;
		this.allowSlideClick = true;
	}


	prepare() {
		const data = this.dataAttr();
		this.currentClass = data.get('currentClass');
		this.handleSwipeAttribute = data.get('handleSwipeAttribute');
		this.handleSlideClickAttribute = data.get('handleSlideClickAttribute');
		this.stopPropagation = data.get('stopPropagation');
		this.handleSwipe = data.get('handleSwipe');
		this.handleSlideClick = data.get('handleSlideClick');
		this.capture = data.get('capture');
		this.swipeTimeoutBeforeClick = data.get('swipeTimeoutBeforeClick');
		this.slideshowIds = data.get('for', []);
		if (!Array.isArray(this.slideshowIds)) {
			this.slideshowIds = [this.slideshowIds];
		}
		let positionSet = false;
		if (this.slideshowIds.length) {
			this.slideshows = [];
			for (const id of this.slideshowIds) {
				const slideshow = this.components.queryComponent(this.root, this.dataSelector('id', id));
				if (slideshow) {
					const slideshowElement = slideshow.getElement();
					if (this.handleSwipe && this.dataAttr(slideshowElement).get(this.handleSwipeAttribute, false)) {
						this.listeners['swipe' + id] = this.events.on(slideshowElement, 'swipe', this.onSwipe.bind(this));
					}
					if (this.handleSlideClick && this.dataAttr(slideshowElement).get(this.handleSlideClickAttribute, false)) {
						this.listeners['slideClick' + id] = this.events.on(slideshowElement, this.dataSelector(slideshow.getSlideAttribute()), 'click', this.onSlideClick.bind(this));
					}
					if (!positionSet) {
						positionSet = true;
						this.listeners.beforeSwitch = this.events.on(slideshowElement, 'slideshow:beforeswitch', this.onBeforeSwitch.bind(this));

						// this should be already done in the markup, this is just to cover corner cases
						const currentSlide = slideshow.getCurrentSlide();
						if (currentSlide) {
							const index = currentSlide.getIndex();
							const triggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, index));
							for (const trigger of triggers) {
								this.classList(trigger).add(this.currentClass);
								trigger.setAttribute('aria-selected', 'true');
							}
						}
					}
					this.slideshows.push(slideshow);
				}
			}
			if (this.slideshows.length) {
				this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this), {capture: this.capture});
			}
		}
	}


	onClick(event, target) {
		event.preventDefault();
		if (this.stopPropagation) {
			event.stopPropagation();
		}
		const index = this.dataAttr(target).get(this.navigationAttribute);
		for (const slideshow of this.slideshows) {
			switch (index) {
				case 'next':
					slideshow.next();
					break;
				case 'prev':
					slideshow.prev();
					break;
				default:
					slideshow.goTo(index);
			}
		}
	}


	onSlideClick(event, target) {
		if (this.allowSlideClick) {
			for (const slideshow of this.slideshows) {
				slideshow.next();
			}
		}
	}


	onSwipe(event) {
		if (this.swipeTimeout) {
			clearTimeout(this.swipeTimeout);
			this.swipeTimeout = null;
		}
		this.allowSlideClick = false;
		for (const slideshow of this.slideshows) {
			if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
				slideshow.prev();
			} else {
				slideshow.next();
			}
		}
		this.swipeTimeout = setTimeout(() => {
			this.allowSlideClick = true;
		}, this.swipeTimeoutBeforeClick);
	}


	onBeforeSwitch(event) {
		if (!event.defaultPrevented) {
			const detail = event.detail;
			const currentSlide = detail.currentSlide;
			const newSlide = detail.newSlide;
			if (currentSlide) {
				const currentTriggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, currentSlide.getIndex()));
				for (const trigger of currentTriggers) {
					this.classList(trigger).remove(this.currentClass);
					trigger.setAttribute('aria-selected', 'false');
				}
			}
			if (newSlide) {
				const newTriggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, newSlide.getIndex()));
				for (const trigger of newTriggers) {
					this.classList(trigger).add(this.currentClass);
					trigger.setAttribute('aria-selected', 'true');
				}
			}
		}
	}
}

export default SlideshowNavigation;
