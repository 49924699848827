import ModalContext from './modal-context';

class NewMenuContext extends ModalContext {
	constructor({name, root, popOnKey = 'esc'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.mainListener = null;
	}

	switchOut() {
		if (this.mainListener) {
			this.mainListener.destroy();
		}
		super.switchOut();
	}

	switchIn() {
		super.switchIn();
	}
}


export default NewMenuContext;
