import keymaster from 'keymaster';
import PageComponent from '../../common/component/page-component';


class Team extends PageComponent {

	constructor({
		root,
		element,
		selectedClass = 'selected',
	}) {
		super({root: root, element: element});
		this.defaults.selectedClass = selectedClass;
	}


	prepare() {
		this.collapsables = this.components.queryComponents(this.element, this.dataSelector('component', 'Collapsable'));

		this.listeners.beforeSwitch = this.events.on(this.element, 'content:resize', this.onToggle.bind(this));

		keymaster('esc', 'team', () => this.pop());
	}


	onToggle(e) {
		this.classList(e.detail.component.element.parentNode).toggle(this.dataAttr().get('selectedClass'), !e.detail.collapsed);

		if (e.detail.collapsed === false) {
			for (const collapsable of this.collapsables) {
				if (collapsable !== e.detail.component && collapsable.collapsed === false) {
					collapsable.collapse();
				}
			}
			keymaster.setScope('team');
		} else {
			//unset scope ??
			//keymaster.setScope('team');
		}
	}

	pop() {
		for (const collapsable of this.collapsables) {
			if (collapsable.collapsed === false) {
				collapsable.collapse();
			}
		}
	}


	destroy() {
		this.collapsables = null;

		keymaster.unbind('esc', 'team');

		super.destroy();
	}

}

export default Team;
