import ContextToggler from '../../common/widget/context-toggler';

class ImageInfo extends ContextToggler {
	constructor({
		root,
		element,
		captionData = 'imageInfoCaption',
		popupData = 'imageInfoPopup',
		closeData = 'imageInfoClose',
		activeClass = 'active',
		contentAttribute = 'contextContent',
		targetContext = 'caption'
}) {
		super({root: root, element: element});
		this.captionData = captionData;
		this.popupData = popupData;
		this.closeData = closeData;
		this.activeClass = activeClass;
		this.contentAttribute = contentAttribute;
		this.targetContext = targetContext;
	}

	prepare() {
		this.captionsContainer = this.root.querySelector(this.dataSelector('captions-container'));
		this.contentNode = this.findContentNode(this.element);
		if (this.contentNode && this.captionsContainer) {
			this.contentNode.parentNode.removeChild(this.contentNode);
			this.captionsContainer.appendChild(this.contentNode);
			super.prepare();
		}
	}

	// traverse up the elements to find the closest content
	findContentNode(el) {
		const contentNode = el.querySelector(this.dataSelector(this.contentAttribute));
		if (contentNode) {
			return contentNode.firstElementChild;
		} else if (el.parentNode)  {
			return this.findContentNode(el.parentNode);
		}
		return false;
	}

	toggle(value) {
		this.captionsContainer.childNodes.forEach((node) => this.classList(node).remove('active'));
		this.classList(this.contentNode).add('active');
		super.toggle(value);
	}

}

export default ImageInfo;
