import {gsap} from 'gsap';
import Tabs from '../../common/widget/tabs';
import offsetTop from '../../common/utils/offset-top';
import {waitFrame} from '../../common/utils/wait';


class FooterMenu extends Tabs {
	constructor({
		root,
		element,
		closeAttribute = 'close',
	}) {
		super({root: root, element: element});
		this.defaults.closeAttribute = closeAttribute;
	}


	prepare() {
		super.prepare();
		const data = this.dataAttr().getAll();
		const closeSelector = this.dataSelector(data.closeAttribute);

		this.listeners.close = this.events.on(this.element, closeSelector, 'click', this.onClose.bind(this));
		// this.listeners.beforeSwitch = this.events.on(this.element, data.switchEvent, this.onBeforeSwitch.bind(this));
		this.listeners.beforeSwitch = this.events.on(this.element, data.beforeSwitchEvent, this.onBeforeSwitch.bind(this));
	}


	onClose() {
		super.select(null, null);
	}


	onBeforeSwitch(event) {
		if (event.detail.newTab) {
			waitFrame().then(() => {
				const position = offsetTop(event.detail.newTab);
				gsap.to(window, {delay: 0.25, duration: 0.25, scrollTo: {y: position, autoKill: false}});
			});
		}
	}
}

export default FooterMenu;
