import PageComponent from '../../common/component/page-component';
import {getScrollTop} from '../../common/utils/get-scroll';
import {getViewportHeight} from '../../common/utils/size';


class ScrollHint extends PageComponent {

	constructor({
		root,
		element,
		hideClass = 'hidden',
		scrollFactor = 0.5,
	}) {
		super({root: root, element: element});
		this.defaults.hideClass = hideClass;
		this.defaults.scrollFactor = scrollFactor;

		this.size = 0;
	}


	prepare() {
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.onResize(null);
	}

	onResize(event) {
		this.size = getViewportHeight() * this.dataAttr().get('scrollFactor');
	}


	onScroll(event) {
		const scrollPos = getScrollTop();

		if (scrollPos > this.size) {
			this.classList().add(this.dataAttr().get('hideClass'));
			this.stop();
		}
	}

}

export default ScrollHint;
