import namedRegexp from 'named-js-regexp';
import {ucFirst} from '../utils/string';


class VideoPlayerDriverFactory {
	constructor({factory}) {
		this.factory = factory;
		this.types = new Map();

		// self hosted videos url is provided as array of URLs, because it can support multiple formats and sizes
		this.types.set('selfHosted', {
			check: (url) => (Array.isArray(url))
		});

		// patterns to recognize the video provider by the URL
		this.types.set('youtube', {
			patterns: [
				'v=(?<videoId>[a-z0-9_-]+)',
				'youtube.com/embed/(?<videoId>[a-z0-9_-]+)',
				'youtu.be/(?<videoId>[a-z0-9_-]+)',
				'youtube-nocookie.com/embed/(?<videoId>[a-z0-9_-]+)'
			]
		});

		this.types.set('vimeo', {
			patterns: [
				'vimeo.com/.+'
			]
		});
	}


	/**
	 *	Provide a different VideoPlayerDriver based on the video URL
	 *
	 * @param {VideoPlayer} controller: the actual Player that is going to use the Driver
	 * @param {Element} element: DOM Element used to attach the player markup (usually whitin an iframe)
	 * @param {String} url: the Video URL
	 * @param {Object} [params]: all the parameters passed from the player to the driver
	 * @returns {VideoPlayerDriver | null} the specific driver created, if the URL matches a corresponding pattern, null otherwise
	 */
	getDriverByUrl(controller, element, url, params = {}) {
		let found = false;
		for (const [type, entry] of this.types) {
			if ('check' in entry && entry.check instanceof Function && entry.check(url)) {
				found = true;
			} else if ('patterns' in entry && !Array.isArray(url)) {
				for (const pattern of entry.patterns) {
					const matches = namedRegexp(pattern, 'i').exec(url);
					const matched = (matches && matches.length > 0);
					if (matched) {
						const urlParams = matches.groups();
						params = Object.assign({}, params, urlParams);
						found = true;
						break;
					}
				}
			}
			if (found) {
				const driverName = ucFirst(type);
				const driver = this.factory.newInstance(driverName, {controller: controller, element: element, url: url, params: params});
				return driver;
			}
		}
		return null;
	}

}


export default VideoPlayerDriverFactory;
