/* simpified version of homepage homeHeadline
	should have the same timing values
*/


import PageComponent from '../../common/component/page-component';

class LettersAnimation extends PageComponent {

	prepare() {
		this.textElement = this.element;
		this.textArr = this.textElement.textContent.split('');

		let txt = '';
		for(let i = 0; i < this.textArr.length; i++) {
			txt += '<span class="tni-lettersAnimation__letter">' + this.textArr[i] + '</span>';
		}
		this.textElement.innerHTML = txt;
	}
}

export default LettersAnimation;
