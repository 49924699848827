import {gsap} from 'gsap';
import {Draggable} from 'gsap/Draggable';
import PageComponent from '../component/page-component';

gsap.registerPlugin(Draggable);


class ContentSliderGsap extends PageComponent {

	constructor({
		root,
		element,
		contentAttribute = 'movingContent',
		preventLinkDrag = true,
		recalculateOnPicturesLoad = true,
		removeNegativeMargins = true,
		directions = 'x,y',
		stepSize = '50%', // without % imply pixels
		stepReference = 'container', // container | content
		stepDuration = 0.4,
		enabled = true,
		updateEvent = 'contentslider:update'
	}) {
		super({root: root, element: element});
		this.defaults.contentAttribute = contentAttribute;
		this.defaults.preventLinkDrag = preventLinkDrag;
		this.defaults.recalculateOnPicturesLoad = recalculateOnPicturesLoad;
		this.defaults.removeNegativeMargins = removeNegativeMargins;
		this.defaults.directions = directions;
		this.defaults.stepSize = stepSize;
		this.defaults.stepReference = stepReference;
		this.stepDuration = stepDuration;
		this.defaults.enabled = enabled;
		this.defaults.updateEvent = updateEvent;

		this.draggable = null;
		this.enabled = null;
		this.enabledBySize = null;
		this.running = false;
		this.tween = null;
		this.tweening = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.updateEvent = data.updateEvent;
		this.enabled = data.enabled;
		this.clickRange = data.clickRange;
		const directions = data.directions.toLowerCase();
		this.directions = {x: directions.indexOf('x') >= 0, y: directions.indexOf('y') >= 0};

		this.content = this.element.querySelector(this.dataSelector(data.contentAttribute));

		if (data.preventLinkDrag) {
			this.listeners.dragLinks = this.events.on(this.element, 'a', 'dragstart', this.onLinkDragStart.bind(this), {capture: true});
		}

		this.listeners.mousedown = this.events.on(this.element, 'mousedown touchstart', this.onMouseDown.bind(this));

		this.draggable = Draggable.create(this.content, {
			type: directions,
			bounds: this.element
		})[0];

		this.draggable.disable();
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		if (data.recalculateOnPicturesLoad) {
			this.listeners.picturesLoad = this.events.on(this.element, 'picture:load', this.onPictureLoad.bind(this));
		}
		this.removeNegativeMargins = data.removeNegativeMargins;
		this.updateBounds();
	}


	start(first) {
		this.updateRunningStatus();
	}


	stop() {
		if (this.running) {
			this.running = false;
			this.draggable.disable();
		}
	}


	onLinkDragStart(event) {
		event.preventDefault();
	}


	onResize(event) {
		this.updateBounds();
	}


	onPictureLoad(event) {
		this.updateBounds();
	}


	onMouseDown(event) {
		// this.mouseDownCoordinates = {x: event.screenX, y: event.screenY};
		if (this.tweening) {
			this.tween.kill();
			this.tween = null;
			this.tweening = false;
			if (this.resolveStep) {
				this.resolveStep();
			}
		}
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.updateRunningStatus();
		}
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			this.updateRunningStatus();
		}
	}


	updateBounds() {
		const containerWidth = this.element.offsetWidth;
		const containerHeight = this.element.offsetHeight;
		let contentWidth = this.content.scrollWidth;
		let contentHeight = this.content.scrollHeight;
		if (this.removeNegativeMargins) {
			const style = getComputedStyle(this.content);
			const topMargin = parseFloat(style.marginTop);
			if (!isNaN(topMargin) && topMargin < 0) {
				contentHeight += topMargin;
			}
			const leftMargin = parseFloat(style.marginLeft);
			if (!isNaN(leftMargin) && leftMargin < 0) {
				contentWidth += leftMargin;
			}
		}
		let enabledBySize = false;
		const diffX = contentWidth - containerWidth;
		const diffY = contentHeight - containerHeight;
		if (this.directions.x && diffX > 0 || this.directions.y && diffY > 0)  {
			enabledBySize = true;
		}
		this.enabledBySize = enabledBySize;
		if (enabledBySize) {
			const bounds = {
				left: -diffX,
				top: -diffY,
				width: contentWidth,
				height: contentHeight
			};

			this.draggable.vars.bounds = bounds;
		}
		this.draggable.update();
		this.updateRunningStatus();
	}


	stepTo(dir) {
		return new Promise((resolve) => {
			this.resolveStep = resolve;
			const coordinate = dir === 'left' || dir === 'right' ? 'x' : 'y';
			const offset = dir === 'left' || dir === 'bottom' ? 1 : -1;
			const stepSize = this.dataAttr().get('stepSize');
			const isPercent = stepSize.indexOf('%') >= 0;
			let value;
			if (isPercent) {
				const stepReference = this.dataAttr().get('stepReference');
				const ratio = parseFloat(stepSize) / 100;
				const node = stepReference === 'container' ? this.element : this.content;
				const prefix = stepReference === 'container' ? 'offset' : 'scroll';
				const suffix = coordinate === 'x' ? 'Width' : 'Height';
				const property = prefix + suffix;
				value = node[property] * ratio;
			} else {
				value = parseInt(stepSize, 10);
			}
			const limits = coordinate === 'x' ? this.boundX : this.boundY;
			const step = value * offset;
			const newValue = Math.max(limits[0], Math.min(limits[1], this[coordinate] + step));
			if (newValue !== this[coordinate]) {
				const dummy = {value: this[coordinate]};
				this.tweening = true;
				this.tween = gsap.to(dummy, {
					duration: this.stepDuration,
					value: newValue,
					onUpdate: () => {
						const params = coordinate === 'x' ? [dummy.value, this.y] : [this.x, dummy.value];
						this.updatePosition(...params, false, true);
					},
					onComplete: () => {
						this.tweening = false;
						resolve();
					}
				});
			}
		});
	}


	updateRunningStatus() {
		if (this.enabled && this.enabledBySize) {
			if (!this.running) {
				this.draggable.enable();
				this.running = true;
			}
		} else if (this.running) {
			this.draggable.disable();
			this.running = false;
		}
		this.triggerUpdateEvent();
	}


	getStatus() {
		const status = {
			enabled: this.enabled,
			enabledBySize: this.enabledBySize,
			running: this.running,
			directions: this.directions
		};
		if (this.directions.x) {
			status.x = this.x;
			// status.boundX = this.boundX;
		}
		if (this.directions.y) {
			status.y = this.y;
			// status.boundY = this.boundY;
		}
		return status;
	}


	triggerUpdateEvent() {
		this.events.trigger(this.element, this.updateEvent, this.getStatus());
	}

}


export default ContentSliderGsap;
