import FormSubmitPreCondition from '../../common/widget/form-submit-pre-condition';
// import {waitFrame} from '../../common/utils/wait';


class FormNewsletter extends FormSubmitPreCondition {

	constructor({
		root,
		element,
		// introAttribute = 'formNewsletterIntro',
		footerLinkAttribute = 'footerMenuToggler',
		nameFieldAttribute = 'footerNameField',
		emailFieldAttribute = 'footerEmailField',
		state1CLass = 'insertedName',
		state2Class = 'insertEmail',
		state3Class = 'response',
		idResponseError = 'mce-error-response',
		idResponseSuccess = 'mce-success-response',
		idMailInput = 'mce-EMAIL',
	}) {
		super({root: root, element: element});
		// this.defaults.introAttribute = introAttribute;
		this.defaults.footerLinkAttribute = footerLinkAttribute;
		this.defaults.nameFieldAttribute = nameFieldAttribute;
		this.defaults.emailFieldAttribute = emailFieldAttribute;
		this.defaults.state1CLass = state1CLass;
		this.defaults.state2Class = state2Class;
		this.defaults.state3Class = state3Class;
		this.defaults.idResponseError = idResponseError;
		this.defaults.idResponseSuccess = idResponseSuccess;
		this.defaults.idMailInput = idMailInput;
	}


	prepare() {
		super.prepare();

		const data = this.dataAttr().getAll();

		// this.listeners.click = this.events.on(this.element, this.dataSelector(data.introAttribute), 'click', this.onClick.bind(this));
		this.listeners.click = this.events.on(this.element, this.dataSelector(data.footerLinkAttribute), 'click', this.onClickDataPolicyLink.bind(this));
		this.listeners.click = this.events.on(this.element, this.dataSelector(data.nameFieldAttribute), 'keyup', this.onInsertName.bind(this));
		this.listeners.click = this.events.on(this.element, this.dataSelector(data.emailFieldAttribute), 'keyup', this.onInsertName.bind(this));

		this.responseErrorNode = this.element.querySelector(`#${data.idResponseError}`);
		this.responseSuccessNode = this.element.querySelector(`#${data.idResponseSuccess}`);
		this.mailInputNode = this.element.querySelector(`#${data.idMailInput}`);
		this.footerMenu = this.components.queryComponent(this.root, this.dataSelector('component', 'FooterMenu'));

		// this.observer = new MutationObserver(this.onDomChange.bind(this));
	}


	onInsertName(event) {
		if (event.target.value.length > 0) {
			this.classList(this.element).add(this.dataAttr().get('state1CLass'));
		}
	}


	onClickDataPolicyLink(event, target) {
		event.preventDefault();

		if (this.footerMenu) {
			const data = this.dataAttr().getAll();
			const tab = this.root.querySelector(this.dataSelector('tab', this.dataAttr(target).get(data.footerLinkAttribute)));
			console.log(tab);
			this.footerMenu.selectByTab(tab);
		}
	}


	// onDomChange(entries) {
	// 	for (let i = 0, end = entries.length; i < end; i++) {
	// 		const entrie = entries[i];

	// 		if (entrie.target === this.responseErrorNode || entrie.target === this.responseSuccessNode) {
	// 			const data = this.dataAttr().getAll();
	// 			this.classList(this.element).remove(data.state2Class);
	// 			this.classList(this.element).add(data.state3Class);
	// 			break;
	// 		}
	// 	}
	// 	this.observer.disconnect();
	// }


	// onClick(event) {
	// 	this.classList(this.element).add(this.dataAttr().get('state2Class'));
	// 	waitFrame().then(() => {
	// 		this.mailInputNode.focus();
	// 	});
	// }


	// onSubmit(event) {
	// 	if (this.submittable) {
	// 		this.observer.observe(this.element, {
	// 			childList: true,
	// 			subtree: true
	// 		});
	// 	}
	// 	super.onSubmit(event);
	// }


	// stop() {
	// 	this.observer.disconnect();
	// }
}

export default FormNewsletter;
