import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import navigationMixin from '../../common/navigation/navigation-mixin';

dayjs.extend(customParseFormat);

// const DATE_INPUT_FORMAT = 'YYYY-MM-DD';


class BookingService extends navigationMixin() {
	/**
	 * @param {object} data fields: in, out, amount
	 * @param {PageComponent} bookingComponent the caller component
	 * @returns	{bool} false for preventing the submit event on the booking form, true otherwise
	 */
	request(data, bookingComponent) {
		console.log('booking data', data);
		return false;
	}
}


export default BookingService;
