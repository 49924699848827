import CaptionContext from './caption-context';
import DefaultContext from './default-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';
import TrackersContext from './trackers-context';
import NewMenuContext from './new-menu-context';


export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
		.setType({type: TrackersContext, name: 'TrackersContext', parent: 'Context'})
		.setType({type: CaptionContext, name: 'CaptionContext', parent: 'Context'})
		.setType({type: NewMenuContext, name: 'NewMenuContext', parent: 'ModalContext'})
	;
};
