import Factory from '../di/factory';


class SectionSlotFactory extends Factory {

	constructor({container, prefix = '', suffix = 'SectionSlot'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}


export default SectionSlotFactory;
