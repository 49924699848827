import Tracker from '../../common/trackers/tracker';


class MatomoTracker extends Tracker {

	getDriver() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.driver = ('_paq' in window ? window._paq : null);
		return this.driver;
	}


	doTrack(url, title) {
		const driver = this.getDriver();
		driver.push(['setCustomUrl', url]);
		driver.push(['setDocumentTitle', title]);
		driver.push(['trackPageView']);
	}


	doTrackEvent(category, action, name = null, value = null) {
		const params = ['trackEvent', category, action];
		if (name !== null) {
			params.push(name);
		}
		if (value !== null) {
			params.push(value);
		}
		this.getDriver().push(params);
	}

}


export default MatomoTracker;
