import Loader from './loader';
import NewsletterAnchor from './newsletter-anchor';
import AnimatedHeadline from './animated-headline';

export default (di) => {
	di
		.setType({type: Loader, name: 'Loader', parent: 'PageComponent'})
		.setType({type: NewsletterAnchor, name: 'NewsletterAnchor', parent: 'PageComponent'})
		.setType({type: AnimatedHeadline, name: 'AnimatedHeadline', parent: 'PageComponent'});
};
