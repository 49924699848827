export function isFunction(value) {
    return typeof value === 'function';
}


export function isNumber(value) {
    return typeof value === 'number' || Object.prototype.toString.call(value) === '[object Number]';
}


export function isString(value) {
    return typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]';
}


export function isArray(value) {
    return Array.isArray(value);
}


export function isObject(value) {
    const type = typeof value;
    return type === 'function' || type === 'object' && !!value;
}


// http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
export function isNumeric(value) {
    return !isArray(value) && (value - parseFloat(value) + 1) >= 0;
}


export function seemsPlainObject(value) {
    return isObject(value) && !(value instanceof Function) && value.constructor === Object;
}


export function toArray(obj) {
    return Array.prototype.slice.call(obj, 0);
}
