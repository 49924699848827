import Context from '../../common/context/context';


class CaptionContext extends Context {

	constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active', isTransitioningAttribute = 'isTransitioning'
							}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
		this.mainListener = null;
		this.isTransitioningAttribute = isTransitioningAttribute;
	}


	getLevel() {
		return 1;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}
	activate() {
		const mainNode = this.root.querySelector(this.dataSelector('main'));
		setTimeout(() => {
			this.main2Listener = this.events.on(mainNode, 'click', this.onMainClick.bind(this));
		}, 100);
		super.activate();
	}
	deactivate() {
		if (this.main2Listener) {
			this.main2Listener.destroy();
		}
		super.deactivate();
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.linkListener = this.events.on(this.root, 'a', 'click', this.onLinkClick.bind(this));
		this.threeStateTransition(element).add(this.activeClass);
		window.scrollTo(0, 0);
		return otherContext;
	}


	switchOut(otherContext) {
		this.dataAttr(this.root).set(this.isTransitioningAttribute, false);

		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		if (this.linkListener) {
			this.linkListener.destroy();
		}
		this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
		return otherContext;
	}


	onLinkClick(event, target) {
		if (this.contexts.getCurrentContext() === this) {
			const href = target.getAttribute('href');
			if (href.charAt(0) !== '#' || !this.getElement().querySelector(href)) {
				event.preventDefault();
				this.contexts.pop().then(() => {
					target.click();
				});
			}
		}
	}

	onMainClick(e) {
		this.contexts.pop();
	}
}


export default CaptionContext;
