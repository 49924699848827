class RuleSet {

	constructor({ruleFactory, ruleSetFactory}) {
		this.last = false;
		this.matched = false;
		this.ruleFactory = ruleFactory;
		this.ruleSetFactory = ruleSetFactory;
		this.rules = [];
		this.matchingRules = [];
	}


	newRule() {
		return this.ruleFactory();
	}


	newRuleSet() {
		return this.ruleSetFactory();
	}


	append(rule) {
		this.rules.push(rule);
		return this;
	}

	prepend(rule) {
		this.rules.unshift(rule);
		return this;
	}


	clearRules() {
		this.rules = [];
		return this;
	}


	getRules() {
		return this.rules;
	}


	match(subject, callbackExtraParams = []) {
		let matched = false;
		this.matchingRules = [];
		for (const rule of this.rules) {
			if (rule.match(subject, callbackExtraParams)) {
				matched = true;
				this.matchingRules.push(rule);
				if (rule.isLast()) {
					break;
				}
			}
		}
		this.matched = matched;
		return matched;
	}


	replace(subject, callbackExtraParams = [], paramsTarget = {}) {
		for (const rule of this.matchingRules) {
			subject = rule.replace(subject, callbackExtraParams, paramsTarget);
		}
		this.matchingRules = [];
		return subject;
	}


	isLast() {
		return this.last;
	}


	setLast(value = true) {
		this.last = !!value;
		return this;
	}


	isMatched() {
		return this.matched;
	}

}

export default RuleSet;
