import Page from './page';
import PageFactory from './page-factory';
import PageLoader from './page-loader';
import ServerRenderedPageLoader from './server-rendered-page-loader';
import ClientRenderedPageLoader from './client-rendered-page-loader';
import Pages from './pages';
import PageSlot from './page-slot';
import SectionSlot from './section-slot';
import PageSlotFactory from './page-slot-factory';
import SectionSlotFactory from './section-slot-factory';
import PageSlots from './page-slots';
import SectionSlots from './section-slots';
import PageTransition from './page-transition';
import PageTransitionFactory from './page-transition-factory';
import PageLoaderFactory from './page-loader-factory';


export default (di) => {
	di
		.setType({
			type: Page, name: 'Page', mixins: ['domMixin', 'listenerMixin', 'navigationMixin', 'componentsMixin', 'contextsMixin'], setters: {
				injectPageSlots: di.lazyGet('page/slots')
			}
		})

		.setType({type: PageFactory, name: 'PageFactory', parent: 'Factory'})
		.set('page/factory', di.lazyNew('PageFactory'))

		.setType({type: PageSlot, name: 'PageSlot', mixins: ['domMixin']})
		.setType({type: SectionSlot, name: 'SectionSlot', mixins: ['domMixin']})

		.setType({type: PageSlotFactory, name: 'PageSlotFactory', parent: 'Factory'})
		.set('page/slotFactory', di.lazyNew('PageSlotFactory'))

		.setType({type: SectionSlotFactory, name: 'SectionSlotFactory', parent: 'Factory'})
		.set('page/sectionSlotFactory', di.lazyNew('SectionSlotFactory'))

		.setType({
			type: PageSlots, name: 'PageSlots', mixins: ['domMixin'], setters: {
				injectSlotFactory: di.lazyGet('page/slotFactory')
			}
		})
		.set('page/slots', di.lazyNew('PageSlots'))

		.setType({
			type: SectionSlots, name: 'SectionSlots', mixins: ['domMixin'], setters: {
				injectSectionSlotFactory: di.lazyGet('page/sectionSlotFactory')
			}
		})
		.set('page/sectionSlots', di.lazyNew('SectionSlots'))

		.setType({
			type: PageLoader, name: 'PageLoader', mixins: ['domMixin', 'navigationMixin'], setters: {
				injectPageFactory: di.lazyGet('page/factory')
			}
		})
		.setType({type: ServerRenderedPageLoader, name: 'ServerRenderedPageLoader', parent: 'PageLoader'})
		.setType({type: ClientRenderedPageLoader, name: 'ClientRenderedPageLoader', parent: 'PageLoader', mixins: ['templateMixin', 'localeMixin', 'apiMixin']})

		.setType({
			type: Pages, name: 'Pages', params: {
				baseUrl: di.lazyValue('baseUrl'),
				events: di.lazyGet('events/events'),
				navigation: di.lazyGet('navigation/navigation'),
				pageTransitionFactory: di.lazyGet('page/transitionFactory'),
				pageLoaderFactory: di.lazyGet('page/loaderFactory'),
				pageTransitionRouter: di.lazyGet('router/pageTransitionRouter'),
				pageLoaderRouter: di.lazyGet('router/pageLoaderRouter')
			}
		})
		.set('page/pages', di.lazyNew('Pages'))

		.setType({
			type: PageTransition, name: 'PageTransition', mixins: ['domMixin', 'componentsMixin', 'contextsMixin', 'navigationMixins'], setters: {
				injectHistory: di.lazyGet('navigation/history'),
				injectSlots: di.lazyGet('page/slots'),
				injectSectionSlots: di.lazyGet('page/sectionSlots')
			}
		})

		.setType({type: PageTransitionFactory, name: 'PageTransitionFactory', parent: 'Factory'})
		.set('page/transitionFactory', di.lazyNew('PageTransitionFactory'))

		.setType({type: PageLoaderFactory, name: 'PageLoaderFactory', parent: 'Factory'})
		.set('page/loaderFactory', di.lazyNew('PageLoaderFactory'))
		;
};
