import PageComponent from '../../common/component/page-component';

class NewDesktopMenu extends PageComponent {
	constructor({
			root,
			element,
			activeClass = 'active',
			buttonData = 'button',
		}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.buttonData = buttonData;
	}

	prepare() {
		this.mainMenuLinks = this.element.querySelectorAll(this.dataSelector(this.buttonData));
		this.listeners.toggle = this.events.on(this.element, this.dataSelector(this.buttonData), 'click', this.onClick.bind(this));
		this.currentElement = null;
		this.currentButton = null;
	}

	onClick(event, target) {
		const forEl = this.dataAttr(target).get('for');
		const el = this.element.querySelector(this.dataSelector('id', forEl));

		if(this.currentElement) {
			this.classList(this.currentElement).remove(this.activeClass);
		}

		if(this.currentButton) {
			this.classList(this.currentButton).remove(this.activeClass);
		}

		this.currentElement = el;
		if(el) {
			this.classList(this.currentElement).add(this.activeClass);
			this.classList(target).add(this.activeClass);
			this.currentButton = target;
		}
	}
}

export default NewDesktopMenu;
