import ClipboardJS from 'clipboard';
import PageComponent from '../../common/component/page-component';

class Share extends PageComponent {

	constructor({
		root,
		element,
		togglerAttribute = 'toggler',
		panelAttribute = 'panel',
		copyAttribute = 'copy',
		openedClass = 'opened'
	}) {
		super({root: root, element: element});
		this.togglerAttribute = togglerAttribute;
		this.panelAttribute = panelAttribute;
		this.copyAttribute = copyAttribute;
		this.openedClass = openedClass;
		this.opened = false;
	}


	prepare() {
		this.panel = this.element.querySelector(this.dataSelector(this.panelAttribute));
		this.listeners.toggler = this.events.on(this.element, this.dataSelector(this.togglerAttribute), 'click', this.onToggle.bind(this));
		this.listeners.in = this.events.on(this.panel, 'a, button', 'click', this.onClickIn.bind(this));
		this.listeners.out = this.events.on(this.root, 'click', this.onClickOut.bind(this));
		this.clipboard = new ClipboardJS(this.element.querySelector(this.dataSelector(this.copyAttribute)), {
			text: (trigger) => this.dataAttr(trigger).get(this.copyAttribute)
		});
	}


	clean() {
		this.clipboard.destroy();
	}


	onToggle(event, target) {
		target.blur();
		if (!this.opened) {
			this.open();
			event.stopPropagation();
		}
	}


	onClickIn(event, target) {
		this.close();
	}


	onClickOut(event, target) {
		if (this.opened && event.target !== this.panel && !this.panel.contains(event.target)) {
			this.close();
		}
	}


	open() {
		if (!this.opened) {
			this.opened = true;
			this.classList().add(this.openedClass);
		}
	}


	close() {
		if (this.opened) {
			this.opened = false;
			this.classList().remove(this.openedClass);
		}
	}

}

export default Share;
