import ModalContext from './modal-context';


class MenuContext extends ModalContext {
	constructor({name, root, popOnKey = 'esc'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.mainListener = null;
	}

	switchOut() {
		if (this.mainListener) {
			this.mainListener.destroy();
		}
		super.switchOut();
	}

	switchIn() {
		const mainNode = this.root.querySelector(this.dataSelector('main'));
		this.mainListener = this.events.on(mainNode, 'click', this.onMainClick.bind(this));
		super.switchIn();
	}


	onMainClick() {
		this.contexts.pop();
	}
}


export default MenuContext;
