import Context from '../../common/context/context';

class ModalContext extends Context {

	constructor({
		name,
		root,
		popOnKey = 'esc',
		fixedClass = 'fixed',
		activeClass = 'active',
		activeStep1Class = 'firstActiveStep',
		activeStep2Class = 'secondActiveStep',
		mainMenuAttribute = 'mainMenu',
		headerAttribute = 'header',
		isTransitioningAttribute = 'isTransitioning'
	}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeStep1Class = activeStep1Class;
		this.activeStep2Class = activeStep2Class;
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
		this.mainMenuAttribute = mainMenuAttribute;
		this.headerAttribute = headerAttribute;
		this.isTransitioningAttribute = isTransitioningAttribute;
	}


	getLevel() {
		return 1;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.linkListener = this.events.on(this.root, 'a', 'click', this.onLinkClick.bind(this));

		//const CollapsingHeaderComponent = this.components.queryComponent(this.root, this.dataSelector(this.headerAttribute));
		//CollapsingHeaderComponent.updateSize();
		requestAnimationFrame(() => {
			this.classList(element).add(this.activeStep1Class);
			requestAnimationFrame(() => {
				this.classList(element).add(this.activeStep2Class);
				requestAnimationFrame(() => {
					this.classList(element).add(this.activeClass);
				});
			});
		});

		window.scrollTo(0, 0);
		this.dataAttr(this.root).set(this.isTransitioningAttribute, false);
		return otherContext;
	}


	switchOut(otherContext) {
		this.dataAttr(this.root).set(this.isTransitioningAttribute, true);
		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		if (this.linkListener) {
			this.linkListener.destroy();
		}

		//const CollapsingHeaderComponent = this.components.queryComponent(this.root, this.dataSelector(this.headerAttribute));

		requestAnimationFrame(() => {
			this.classList(element).remove(this.activeClass);
			this.classList(element).remove(this.activeStep2Class);
			this.classList(element).remove(this.activeStep1Class);
			//CollapsingHeaderComponent.updateSize();
			this.dataAttr(this.root).set(this.isTransitioningAttribute, false);
		});
		return otherContext;
	}


	onLinkClick(event, target) {
		if (this.contexts.getCurrentContext() === this) {
			const href = target.getAttribute('href');
			if (href.charAt(0) !== '#' || !this.getElement().querySelector(href)) {
				event.preventDefault();
				this.contexts.pop().then(() => {
					target.click();
				});
			}
		}
	}

}


export default ModalContext;
