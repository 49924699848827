import PageComponent from '../../common/component/page-component';


class ProfileHero extends PageComponent {
	constructor({
		root,
		element,
		profileWrapperVideo = 'profileWrapperVideo',
		profileHeroAltText = 'profileHeroAltText',
		video = 'embed'
	}) {
		super({root: root, element: element});
		this.profileWrapperVideo = profileWrapperVideo;
		this.profileHeroAltText = profileHeroAltText;
		this.video = video;
	}

	prepare() {
		this.profileWrapperVideo = this.element.querySelector(this.dataSelector(this.profileWrapperVideo));
		this.profileHeroAltText = this.element.querySelector(this.dataSelector(this.profileHeroAltText));
		this.video = this.element.querySelector(this.dataSelector(this.video));
		if(this.video) {
			this.video.addEventListener('play', this.isActiveVideo.bind(this));
		}
	}

	isActiveVideo() {
		this.profileHeroAltText.style.visibility = 'hidden';
	}
}

export default ProfileHero;
