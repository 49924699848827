import {gsap} from 'gsap';
import PageComponent from '../../common/component/page-component';

class Ticker extends PageComponent {

	constructor({
		root,
		element,
		wrapperData = 'wrapper',
		tickerTextData = 'tickerText',
	}) {
		super({root: root, element: element});
		this.wrapperData = wrapperData;
		this.tickerTextData = tickerTextData;
	}

	prepare() {
		this.speed = 4;
		this.wrapper = this.element.querySelector(this.dataSelector(this.wrapperData));
		this.tickerText = this.wrapper.querySelector(this.dataSelector(this.tickerTextData));
		this.textWidth = this.tickerText.clientWidth;
		this.time = (this.textWidth / this.speed) * (60/1000);
		this.className = 'tni-newNewsletter__tickerTextLine';
		this.elementTagName = 'span';
		this.tl = gsap.timeline();

		this.tickerAnimation();

		this.checkResize();

		window.addEventListener('resize', this.checkResize.bind(this));

		this.wrapper.addEventListener('mouseenter', () => {
			this.tl.pause();
		});
		this.wrapper.addEventListener('mouseleave', () => {
			this.tl.play();
		});
	}

	makeClones() {
		for(let i = 0; i < 15; i++) {
			this.createClone();
		}
		//this.tickerText.innerHTML = '';
	}

	createClone() {
		const clone = this.tickerText.cloneNode(true);
		//const separator = document.createElement(this.elementTagName);
		//separator.classList.add(this.className);
		//separator.textContent = '|';

		//clone.prepend(separator);
		this.wrapper.appendChild(clone);
	}

	tickerAnimation() {
		const tween = this.tl
		.to(this.wrapper,
			{
			  duration: this.time,
				ease: 'none',
				repeat: 999,
				x: '-' + (this.textWidth - this.speed),
			}
		);

		return tween;
	}

	checkResize() {
		if(this.wrapper.clientWidth <= window.innerWidth) {
			for(let i = 0; i < 15; i++) {
				this.createClone();
			}
		}
	}
}

export default Ticker;
