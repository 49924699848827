import FooterMenu from './footer-menu';
import ScrollHint from './scroll-hint';
import ScrollIndicator from './scroll-indicator';
import Team from './team';
import LettersAnimation from './letters-animation';
import NewMainMenu from './new-main-menu';
//import NewHeader from './new-header';
import NewDesktopMenu from './new-desktop-menu';
import NewMenuToggler from './new-menu-toggler';

export default (di) => {
	di
		.setType({type: FooterMenu, name: 'FooterMenu', parent: 'Tabs'})
		.setType({type: ScrollHint, name: 'ScrollHint', parent: 'PageComponent'})
		.setType({type: ScrollIndicator, name: 'ScrollIndicator', parent: 'PageComponent', setters: {
			injectIntersectionObservers: di.lazyGet('dom/intersectionObservers')
		}})
		.setType({type: Team, name: 'Team', parent: 'PageComponent'})
		.setType({type: LettersAnimation, name: 'LettersAnimation', parent: 'PageComponent'})
		.setType({type: NewMainMenu, name: 'NewMainMenu', parent: 'PageComponent'})
		//.setType({type: NewHeader, name: 'NewHeader', parent: 'PageComponent'})
		.setType({type: NewDesktopMenu, name: 'NewDesktopMenu', parent: 'PageComponent'})
		.setType({type: NewMenuToggler, name: 'NewMenuToggler', parent: 'PageComponent'})
		;
};
