import PageComponent from '../../common/component/page-component';

class NewScaleImage extends PageComponent {

	constructor({
		root,
		element,
		classAttribute = 'class',
		toggledClass = 'toggled',
		imgScaleClass = 'imgScale',
		imgNotScaleClass = 'imgNotScale',
		titleData = 'title',
		imageData = 'image'
	}) {
		super({root: root, element: element});
		this.classAttribute = classAttribute;
		this.toggledClass = toggledClass;
		this.target = null;
		this.imgScaleClass = imgScaleClass;
		this.imgNotScaleClass = imgNotScaleClass;
		this.titleData = titleData;
		this.imageData = imageData;
	}

	prepare() {
		const id = this.dataAttr().get('for');
		this.target = this.root.querySelector(this.dataSelector('id', id));
		this.title = this.element.querySelector(this.dataSelector(this.titleData));
		this.image = this.element.querySelector(this.dataSelector(this.imageData));

		if (this.target && this.image) {
			this.className = this.dataAttr().get(this.classAttribute);
			this.listeners.toggle = this.events.on(this.element, 'mouseover', this.onToggle.bind(this));
		}
	}

	onToggle(event) {
		event.preventDefault();
		this.element.blur();
		this.classList(this.target).toggle(this.className);
		this.classList().toggle(this.toggledClass);

		this.title.addEventListener('mouseover', (e) => {
			e.preventDefault();
			this.classList(this.image).add(this.imgScaleClass).remove(this.imgNotScaleClass);
		});
		this.title.addEventListener('mouseout', (e) => {
			e.preventDefault();
			this.classList(this.image).remove(this.imgScaleClass).add(this.imgNotScaleClass);
		});
	}

}

export default NewScaleImage;
