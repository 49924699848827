import Page from '../../common/page/page';


class DefaultPage extends Page {
	constructor({
		root,
		element,
		observedElementAttribute = 'observed',
		intersectedModifier = 'intersected',
		intersectedJirgsawModifier = 'intersectedJirgsaw'
	}) {
		super({root: root, element: element});
		this.intersectionObserverId = this;
		this.observedElementAttribute = observedElementAttribute;
		this.intersectedModifier = intersectedModifier;
		this.intersectionJirgsawObserverId = 'jirgsaw';
		this.intersectedJirgsawModifier = intersectedJirgsawModifier;

		this.threshold = 0.4; //50%
		this.offsetTop = '20px';
		this.offsetBottom = '0px';
	}

	injectIntersectionObservers(intersectionObservers) {
		this.intersectionObservers = intersectionObservers;
	}

	onLoad() {
		this.setObserver();
	}


	setObserver() {
		const observedNodes = this.root.querySelectorAll(this.dataSelector(this.observedElementAttribute));
		this.intersectionObservers.initObserver(
			this.intersectionObserverId,
			this.threshold,
			this.onIntersect.bind(this),
			this.offsetTop + ' 0px ' + this.offsetBottom + ' 0px'
		);
		this.intersectionObservers.initObserver(
			this.intersectionJirgsawObserverId,
			0.2,
			this.onIntersectJirgsaw.bind(this),
			'90% 0px 0px 0px'
		);
		this.intersectionObservers.observe(this.intersectionObserverId, observedNodes);
		this.intersectionObservers.observe(this.intersectionJirgsawObserverId, observedNodes);
	}


	onIntersectJirgsaw(entries) {
		for (const entry of entries) {
			if(entry.isIntersecting) {
				this.classList(entry.target).add(this.intersectedJirgsawModifier);
			}
		}
	}


	onIntersect(entries) {
		for (const entry of entries) {
			if (entry.isIntersecting) {
				this.classList(entry.target).add(this.intersectedModifier);
			}
		}
	}

	onUnload() {
		this.intersectionObservers.destroyObserver(this.intersectionObserverId);
	}
}


export default DefaultPage;
