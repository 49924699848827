import {gsap, ScrollToPlugin, InertiaPlugin, Draggable} from 'gsap/all';
import keymaster from 'keymaster';
import PageComponent from '../../common/component/page-component';

class NewTextSlider extends PageComponent {
	constructor({
			root,
			element,
			categorySlider = 'categorySlider',
			containerData = 'container',
			progressData = 'progress',
			progressBarData = 'progressBar',
			contentData = 'content',
			mediaData = 'media',
			cursorData = 'cursor',
			cursorIconData = 'cursorIcon',
			itemData = 'scrolledItem',
			largeHeaderData = 'largeHeader',
			rotateClass = 'rotate',
			wrapperInner = 'wrapperInner'
		}) {
		super({root: root, element: element});

		this.containerData = containerData;
		this.categorySlider = categorySlider;
		this.progressData = progressData;
		this.progressBarData = progressBarData;
		this.contentData = contentData;
		this.mediaData = mediaData;
		this.cursorData = cursorData;
		this.cursorIconData = cursorIconData;
		this.itemData = itemData;
		this.largeHeaderData = largeHeaderData;
		this.rotateClass = rotateClass;
		this.wrapperInner = wrapperInner;
	}

	prepare() {
		gsap.registerPlugin(ScrollToPlugin, Draggable, InertiaPlugin);
		this.currentXPos = 0;
		this.ignoreClicks = false;
		this.ignoreInterval = null;
		this.progress = this.element.querySelector(this.dataSelector(this.progressData));
		this.progressBar = this.element.querySelector(this.dataSelector(this.progressBarData));
		this.category = this.element.querySelector(this.dataSelector(this.categorySlider));
		this.container = this.element.querySelector(this.dataSelector(this.containerData));
		this.content = this.element.querySelector(this.dataSelector(this.contentData));
		this.media = this.element.querySelector(this.dataSelector(this.mediaData));
		this.item = this.element.querySelector(this.dataSelector(this.itemData));
		this.items = this.element.querySelectorAll(this.dataSelector(this.itemData));
		this.cursor = this.element.querySelector(this.dataSelector(this.cursorData));
		this.cursorIcon = this.element.querySelector(this.dataSelector(this.cursorIconData));
		this.largeHeader = this.element.querySelector(this.dataSelector(this.largeHeaderData));
		this.wrapperInnerSlider = this.element.querySelector(this.dataSelector(this.wrapperInner));

		this.isActive = true;
		this.currentProgress = 0;
		this.isRotated = false;

		if(this.items.length) {
			this.draggable = Draggable.create(this.container, {
				type: 'scrollLeft',
				lockAxis: true,
				inertia: true,
				autoScroll: true
			});

			window.addEventListener('resize', this.onResize.bind(this));
			this.listeners.scroll = this.container.addEventListener('scroll', this.onScroll.bind(this));
			this.listeners.wheel = this.container.addEventListener('wheel', this.onScroll.bind(this));
			this.listeners.mousemove = document.addEventListener('mousemove', this.onMousemove.bind(this));

			/*this.listeners.click = this.container.addEventListener('click', (e) => {
				if (this.ignoreClicks) {
					e.stopPropagation();
					e.preventDefault();
					e.stopImmediatePropagation();
					return false;
				}
				return false;
			}, true);*/

			this.onResize();

			this.listeners.click = this.cursor.addEventListener('click', this.onArrowClick.bind(this));

			keymaster('left', this.onKeyLeft.bind(this));
			keymaster('right', this.onKeyRight.bind(this));
		}
	}

	onKeyLeft() {
		if(this.isInViewport()) this.onNavButton(-1);
	}

	onKeyRight() {
		if(this.isInViewport()) this.onNavButton(1);
	}


	onArrowClick(e) {
		//e.preventDefault();

		if(!this.isActive) return;

		const rect = this.element.getBoundingClientRect();
		const x = e.clientX - rect.left;

		if(((x < window.innerWidth * 0.5))) {
			this.onNavButton(-1);
		} else {
			this.onNavButton(1);
		}
	}

	checkArrows(mouseEvent) {
		const rect = this.element.getBoundingClientRect();
		const x = mouseEvent.clientX - rect.left;

		if((x > window.innerWidth * 0.5)) {
			this.isRotated = false;
			this.classList(this.cursor).remove(this.rotateClass);
		} else {
			this.isRotated = true;
			this.classList(this.cursor).add(this.rotateClass);
		}

		this.checkArrowActivity();
	}

	checkArrowActivity() {
		if((this.isRotated && this.currentXPos === 0) || (!this.isRotated && this.currentProgress === 1)) {
			this.cursorIcon.style.display = 'none';
			this.isActive = false;
		} else {
			this.cursorIcon.style.display = 'block';
			this.isActive = true;
		}

		if(this.container.scrollWidth - this.container.offsetWidth === 0) {
			this.cursor.style.visibility = 'hidden';
		} else {
			this.cursor.style.visibility = 'visible';
		}
	}

	unfocus() {
		document.activeElement.blur();
	}

	onMousemove(event) {
		const rect = this.element.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;

		if(this.largeHeader) {
			if(y > (this.containerHeight - this.contentHeight - 60) || y < 0) {
				this.hideCursor();
			} else {
				this.showCursor();
				gsap.to(this.cursor, 0, {x: x, y: y});
			}
		} else {
			if(y > (this.mediaHeight + 30) || y < 0) {
				this.hideCursor();
			} else {
				this.showCursor();
				gsap.to(this.cursor, 0, {x: x, y: y});
			}
		}

		this.checkArrows(event);
	}

	hideCursor() {
		this.cursor.style.display = 'none';
	}

	showCursor() {
		this.cursor.style.display = 'block';
	}

	onDragStart(event) {
		event.preventDefault();
	}

	onNavButton(direction) {
		const targetPosition = Math.max(0, Math.min(this.container.scrollWidth - this.container.offsetWidth, - this.currentXPos + direction * this.tileWidth));
		gsap.to(this.container, {duration: 0.7, scrollLeft: targetPosition, ease: 'Power4.easeOut', onComplete: this.onComplete.bind(this)});
		this.currentXPos = -targetPosition;

		this.setProgressAnim();
	}

	updateContainer(x, y) {
		this.container.scrollTo(-x, 0);
		this.currentXPos = x;
		this.ignoreClicks = true;
		clearTimeout(this.ignoreInterval);
		this.ignoreInterval = setTimeout(() => {
			this.ignoreClicks = false;
		}, 500);

		this.setProgress();
	}

	reset() {
		this.container.scrollTo(0, 0);
	}

	checkTileWidth() {
		if(this.items.length >= 2) {
			const rect1 = this.items[0].getBoundingClientRect();
			const rect2 = this.items[1].getBoundingClientRect();
			this.tileWidth = (rect2.left - rect1.left) * 3;
		} else {
			this.tileWidth = 0;
		}
	}

	onResize() {
		const diff = this.container.offsetWidth - this.container.scrollWidth;

		if(diff < 0) {
			this.showProgressBar();
		} else {
			this.reset();
			this.hideProgressBar();
		}

		this.checkTileWidth();

		this.setProgress();

		this.containerHeight = this.container.clientHeight;
		this.contentHeight = this.content ? this.content.clientHeight : 0;
		this.itemWidth = this.item.clientWidth;
		this.mediaHeight = this.media ? this.media.clientHeight : 0;

		//for category in new person slider
		if(this.category && this.wrapperInner) {
			if (window.innerWidth < 1680) {
				this.category.setAttribute('style', `top: ${this.containerHeight - this.wrapperInnerSlider.offsetHeight}px`);
			} else {
				this.category.removeAttribute('style');
			}
		}
	}

	setProgress() {
		const p =  Math.min(1, Math.abs((-this.currentXPos + this.container.offsetWidth)/this.container.scrollWidth));
		this.progress.style.width = p * 100 + '%';

		this.currentProgress = p;
		this.checkArrowActivity();
	}

	hideProgressBar() {
		this.progressBar.style.opacity = 0;
	}

	showProgressBar() {
		this.progressBar.style.opacity = 1;
	}

	onScroll(event) {
		this.currentXPos = -this.container.scrollLeft;
		this.setProgress();
	}

	onComplete() {
		this.unfocus();
	}

	setProgressAnim() {
		const p =  Math.min(1, Math.abs((-this.currentXPos + this.container.offsetWidth)/this.container.scrollWidth));
		gsap.to(this.progress, {duration: 0.7, width: p * 100 + '%', ease: 'Power4.easeOut'});

		this.currentProgress = p;
		this.checkArrowActivity();
	}

	isInViewport() {
		const {top, bottom} = this.element.getBoundingClientRect();
		const vHeight = (window.innerHeight || document.documentElement.clientHeight);

		return (
			(top > 0 || bottom > 0) &&
			top < vHeight
			);
	}
}

export default NewTextSlider;
