import PageComponent from '../component/page-component';


class VideoPlayerCover extends PageComponent {

	constructor({
		element,
		root,
		linkAttribute = 'videoLink',
		loadingClass = 'loading',
		loadedClass = 'loaded',
		videoBlockData = 'videoBlock',
		loopedVideoAttribute = 'loopedVideo'
	} = {}) {
		super({element: element, root: root});
		this.defaults.linkAttribute = linkAttribute;
		this.loadingClass = loadingClass;
		this.loadedClass = loadedClass;
		this.videoBlockData = videoBlockData;
		this.loopedVideoAttribute = loopedVideoAttribute;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.link = this.element.querySelector(this.dataSelector(data.linkAttribute));
		this.player = this.getComponent('VideoPlayer');
		if (this.link && this.player) {
			this.listeners.click = this.events.on(this.element, this.dataSelector(data.linkAttribute), 'click', this.onClick.bind(this));
		}
	}


	onClick(event) {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			const classList = this.classList();
			classList.add(this.loadingClass);
			this.player.load().then(() => {

				const block = this.element.parentNode.closest(this.dataSelector(this.videoBlockData));
				const loopedVideoNode = block.querySelector(this.dataSelector(this.loopedVideoAttribute));
				if (loopedVideoNode) {
					const loopedVideoComponent = this.getComponent(loopedVideoNode);
					loopedVideoComponent.stop();
				}

				classList.remove(this.loadingClass);
				classList.add(this.loadedClass);
				if(block) {
					this.classList(block).add(this.loadedClass);
				}
			});
		}
	}

}


export default VideoPlayerCover;
