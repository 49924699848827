import PageComponent from '../component/page-component';


class FormSubmitPreCondition extends PageComponent {

	constructor({
		root,
		element,
		conditionsAttribute = 'conditions',
		submittable = false	// default state
	}) {
		super({root: root, element: element});
		this.conditionsAttribute = conditionsAttribute;
		this.conditionsFields = new Map();
		this.defaults.submittable = submittable;
	}


	prepare() {
		this.submittable = this.dataAttr().get('submittable');
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this), {capture: true});
		this.listeners.change = this.events.on(this.element, '[name]', 'change', this.onChange.bind(this));
		this.conditions = this.dataAttr().get(this.conditionsAttribute, {});
		for (const name in this.conditions) {
			if (this.conditions.hasOwnProperty(name)) {
				const field = this.element.querySelector('[name="' + name + '"]');
				if (field) {
					this.conditionsFields.set(field, this.conditions[name]);
				}
			}
		}
		this.submitFields = this.element.querySelectorAll('[type="submit"]');
		this.checkConditions();
	}


	onSubmit(event) {
		if (!this.submittable) {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
		}
	}


	onChange(event, target) {
		const name = target.getAttribute('name');
		if (name in this.conditions) {
			this.checkConditions();
		}
	}


	checkConditions() {
		let submittable = true;
		for (const [field, value] of this.conditionsFields) {
			const type = field.getAttribute('type');
			const match = (type === 'checkbox' || type === 'radio') ?
				field.checked === !!value :
				field.value === value
			;
			if (!match) {
				submittable = false;
				break;
			}
		}
		this.updateSubmittable(submittable);
	}


	updateSubmittable(submittable) {
		if (submittable !== this.submittable) {
			this.submittable = submittable;
			for (const submitField of this.submitFields) {
				submitField.disabled = !this.submittable;
			}
		}

	}


}


export default FormSubmitPreCondition;
