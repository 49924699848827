import TweenMax from 'gsap';
import PageComponent from '../../common/component/page-component';
import {getScrollTop} from '../../common/utils/get-scroll';

class TeaserBlock extends PageComponent {
	constructor({
		root,
		element,
		imageData = 'blockImage',
		titleData = 'blockTitle',
		imageMovement = 15,
		textMovement = 25,
		duration = 0.6
	}) {
		super({root: root, element: element});
		this.imageData = imageData;
		this.titleData = titleData;
		this.imageMovement = imageMovement;
		this.textMovement = textMovement;
		this.duration = duration;
	}

	prepare() {
		this.image = this.element.querySelector(this.dataSelector(this.imageData));
		this.title = this.element.querySelector(this.dataSelector(this.titleData));
		this.listeners.moveListener = this.events.on(this.element, 'mousemove', this.onMouseMove.bind(this));
		this.listeners.outListener = this.events.on(this.element, 'mouseout', this.onMouseOut.bind(this));
		this.listeners.overListener = this.events.on(this.element, 'mouseover', this.onMouseOver.bind(this));

		TweenMax.to(this.image, 0, {
			scale: 1
		});
	}

	onMouseMove(e) {
		const rect = this.element.getBoundingClientRect();
		const relX = e.pageX - rect.left;
		const relY = e.pageY - rect.top - getScrollTop();
		const w = rect.width;
		const h = rect.height;
		const diffX = (relX - w / 2) / w;
		const diffY = (relY - h / 2) / h;

		TweenMax.to(this.image, this.duration, {
			x: - diffX * this.imageMovement,
			y: - diffY * this.imageMovement
		});

		TweenMax.to(this.title, this.duration, {
			x: - diffX * this.textMovement,
			y: - diffY * this.textMovement
		});
	}

	onMouseOut(e) {
		TweenMax.to(this.image, this.duration, {
			x: 0,
			y: 0
		});

		TweenMax.to(this.title, this.duration, {
			x: 0,
			y: 0
		});

		TweenMax.to(this.image, this.duration * 1.5, {
			scale: 1
		});
	}

	onMouseOver(e) {
		TweenMax.to(this.image, this.duration * 1.5, {
			scale: 1.02
		});
	}
}

export default TeaserBlock;
