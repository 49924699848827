import Impetus from 'impetus';
import PageComponent from '../../common/component/page-component';

class NewSlidingMenu extends PageComponent {

	constructor({
			root,
			element,
			arrowClickDistance = 100,
			arrowBtn = 'arrowBtn',
			containerData = 'container'
		}) {
		super({root: root, element: element});
		this.arrowBtn = arrowBtn;
		this.containerData = containerData;
		this.arrowClickDistance = arrowClickDistance;
	}

	prepare() {
		this.arrow = this.element.querySelector(this.dataSelector(this.arrowBtn));
		this.isArrowHidden = false;
		this.container = this.element.querySelector(this.dataSelector(this.containerData));

		this.impetus = new Impetus({
			source: this.container,
			boundX: [0, 0],
			initialValues: [0, 0],
			update: this.update.bind(this)
		});

		this.listeners.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));


		this.listeners.click = this.arrow.addEventListener('click', (e) => {
			this.onClick();
		}, true);

		this.onResize();
  }

	update(x, y) {
		this.container.style.transform = 'translate(' + x + 'px, 0)';
		this.currentXPos = x;
		if (x !== 0) {
			this.hideArrow();
		}
	}

	showArrow() {
		this.arrow.style.display = 'block';
	}


	hideArrow() {
		this.arrow.style.display = 'none';
		this.isArrowHidden = true;
	}

	onResize() {
		const diff = this.container.offsetWidth - this.container.scrollWidth;
		if(diff < 0) {
			this.impetus.resume();
			this.impetus.setBoundX([diff, 0]);
			this.showArrow();
		} else {
			this.reset();
			this.impetus.pause();
			this.hideArrow();
		}
	}

	onClick() {
		this.update(this.currentXPos - this.arrowClickDistance); //TODO
	}

	clear() {
		this.impetus.destroy();
	}

	reset() {
		this.container.style.transform = 'translate(' + 0 + ', 0)';
	}
}

export default NewSlidingMenu;
