import domMixin from '../dom/dom-mixin';
import listenerMixin from '../events/listener-mixin';
import navigationMixin from '../navigation/navigation-mixin';
import componentsMixin from '../component/components-mixin';
import contextsMixin from '../context/contexts-mixin';


class Page extends contextsMixin(componentsMixin(navigationMixin(listenerMixin(domMixin())))) {

	constructor({root, element, url = '', title = ''}) {
		super();
		this.root = root;
		this.element = element;
		this.pageSlot = null;
		this.url = url;
		this.title = title;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	setPageSlot(pageSlot) {
		this.pageSlot = this.pageSlots.get(pageSlot);
		return this;
	}


	getPageSlot() {
		return this.pageSlot;
	}


	setUrl(url) {
		this.url = url;
		return this;
	}


	getUrl() {
		return this.url;
	}


	setTitle(title) {
		this.title = title;
		return this;
	}


	getTitle() {
		return this.title;
	}


	getRoot() {
		return this.root;
	}


	getElement() {
		return this.element;
	}


	attach() {
		if (this.pageSlot === null) {
			throw new Error('Page Slot not defined, unable to attach page to DOM');
		}
		this.pageSlot.attach(this.element);
		return this;
	}


	setAsCurrent() {
		if (this.pageSlot === null) {
			throw new Error('Page Slot not defined');
		}
		this.pageSlot.setCurrentPage(this);
		return this;
	}


	onLoad() {
		return Promise.resolve();
	}


	onUnload() {
		this.destroyListeners();
		return Promise.resolve();
	}

}


export default Page;
