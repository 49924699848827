import PageComponent from '../component/page-component';
import {getScrollTop} from '../utils/get-scroll';
// import {getViewportHeight} from '../../common/utils/size';


class CollapsingHeader extends PageComponent {

	constructor({
		root,
		element,
		collapsingClass = 'collapsing',
		collapsedClass = 'collapsed',
		collapseWithStates = false,
		activeInContext = 'default',
		isTransitioningAttribute = 'isTransitioning',
		disabledOnTransition = false,
	}) {
		super({root: root, element: element});
		this.defaults.collapsingClass = collapsingClass;
		this.defaults.collapsedClass = collapsedClass;
		this.defaults.collapseWithStates = collapseWithStates;
		this.defaults.activeInContext = activeInContext;
		this.defaults.disabledOnTransition = disabledOnTransition;
		this.isTransitioningAttribute = isTransitioningAttribute;

		this.size = 0;
		this.sizeBottom = 0;
		this.previousScrollPos = -1;
		this.collapsing = false;
		this.collapsed = false;
	}


	prepare() {
		this.collapsingClass = this.dataAttr().get('collapsingClass');
		this.collapsedClass = this.dataAttr().get('collapsedClass');
		this.collapsedWidthStates = this.dataAttr().get('collapseWithStates');
		this.activeInContext = this.dataAttr().get('activeInContext');
		this.disabledOnTransition = this.dataAttr().get('disabledOnTransition');
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.updateSize();
	}


	onResize(event) {
		if (this.disabledOnTransition) {
			this.isTransitioning = this.dataAttr(this.root).get(this.isTransitioningAttribute);
		}

		if (this.contexts.getCurrentContext().name === this.activeInContext && !this.isTransitioning) {
			this.updateSize();
		}
	}


	onScroll(event) {
		if (this.disabledOnTransition) {
			this.isTransitioning = this.dataAttr(this.root).get(this.isTransitioningAttribute);
		}

		if (this.contexts.getCurrentContext().name === this.activeInContext && !this.isTransitioning) {
			this.updateState();
		}
	}


	updateSize() {
		// this.size = this.element.getBoundingClientRect().height;
		this.size = 20;
		// this.sizeBottom = getViewportHeight() * 0.25;
		this.updateState();
	}


	updateState() {
		const scrollPos = getScrollTop();
		if (scrollPos !== this.previousScrollPos) {
			const direction = scrollPos >= this.previousScrollPos ?  1 : -1;
			const newCollapsing = (scrollPos > this.size);
			const newCollapsed = (scrollPos > this.size && direction === 1);
			if (newCollapsing !== this.collapsing) {
				this.classList().toggle(this.collapsingClass, newCollapsing);
				this.collapsing = newCollapsing;
			}
			if (newCollapsed !== this.collapsed) {
				if (this.collapsedWidthStates) {
					if (newCollapsed) {
						this.threeStateTransition(this.element).add(this.collapsedClass);
					} else {
						this.threeStateTransition(this.element).remove(this.collapsedClass);
					}
				} else {
					this.classList().toggle(this.collapsedClass, newCollapsed);
				}
				this.collapsed = newCollapsed;
			}
			this.previousScrollPos = scrollPos;
		}
	}

}


export default CollapsingHeader;
