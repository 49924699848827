// needed to interact with google map api
/*eslint-disable piggyback/no-restricted-global-extend*/


class YoutubeApiLoader {

    constructor({url = 'https://www.youtube.com/iframe_api', callbackName = 'onYouTubeIframeAPIReady'} = {}) {
        this.url = url;
        this.callbackName = callbackName;
		this.script = null;
		this.promise = null;
		this.promiseResolveTrigger = null;
        this.youtube = null;
	}


    load() {
		if (this.promise === null) {
			this.promise = new Promise((resolve) => {
				this.promiseResolveTrigger = resolve;
				window[this.callbackName] = this.onYoutubeApiReady.bind(this);
				this.script = document.createElement('script');
				this.script.async = true;
				this.script.defer = true;
				this.script.setAttribute('src', this.url);
				document.body.appendChild(this.script);
			});
		}
		return this.promise;
	}


    onYoutubeApiReady() {
		console.log('youtube api ready');
		this.youtube = window.YT;
		this.promiseResolveTrigger(this.youtube);
    }

}


export default YoutubeApiLoader;
