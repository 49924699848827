import HistoryApi from './history-api';
import Navigation from './navigation';
import navigationMixin from './navigation-mixin';
import HashScroller from './hash-scroller';
import CurrentLinkManager from './current-link-manager';


export default (di) => {
	di
		.setType({type: HistoryApi, name: 'HistoryApi', params: {
				events: di.lazyGet('events/events')
			}
		})
		.set('navigation/history', di.lazyNew('HistoryApi'))

		.setType({type: Navigation, name: 'Navigation', mixins: ['domMixin'], initCall: 'init', params: {
				history: di.lazyGet('navigation/history'),
				baseUrl: di.lazyValue('baseUrl', '')
			}
		})
		.set('navigation/navigation', di.lazyNew('Navigation'))

		.setMixin({mixin: navigationMixin, name: 'navigationMixin', setters: {
				injectNavigation: di.lazyGet('navigation/navigation')
			}
		})

		.setType({type: HashScroller, name: 'HashScroller', mixins: ['domMixin'], params: {
				events: di.lazyGet('events/events')
			}
		})

		.setType({type: CurrentLinkManager, name: 'CurrentLinkManager', mixins: ['domMixin'], initCall: 'init'})
		.set('navigation/currentLinkManager', di.lazyNew('CurrentLinkManager'))
	;
};
