import PageTransition from '../../common/page/page-transition';
import {wait} from '../../common/utils/wait';

class DefaultPageTransition extends PageTransition {
	swapPages(newPage, oldPage, request, response) {

		//set current page to menu list items
		const pageTitle = document.querySelector('[data-tni-page]').dataset.tniPageTitle;
		const menuListitems = document.querySelectorAll('[data-tni-menu-item]');

		for (const menuListitem of menuListitems) {
			const itemPageTitle = menuListitem.dataset.tniPageTitle;
			menuListitem.classList.remove('tni-js-active');

			if (itemPageTitle === pageTitle.replace(/ /g, '').toLowerCase()) {
				menuListitem.classList.add('tni-js-active');
			}
		}

		this.checkColorVersion();
		return Promise.resolve();
	}

	beginAnimation(request) {
		this.components.getComponent(document, 'Loader').playAnimation();
		return wait(0.8);
	}


	endAnimation(request, response) {
		this.components.getComponent(document, 'Loader').removeAnimation();
		return wait(0);
	}

	setBodyClasses(newPage, oldPage, request, response) {
		if (oldPage && oldPage.getPageSlot() === newPage.getPageSlot()) {
			const bodyClasses = this.dataAttr(oldPage.getElement()).get('bodyClasses', []);
			if (bodyClasses.length) {
				this.classList(document.body).remove(...bodyClasses);
			}
		}
		const bodyClasses = this.dataAttr(newPage.getElement()).get('bodyClasses', []);
		if (bodyClasses.length) {
			this.classList(document.body).add(...bodyClasses);
		}
	}

	checkColorVersion() {
		this.blocks = document.querySelector(this.dataSelector('blocks'));
		if(this.blocks) {
			this.firstSection = this.blocks.firstElementChild;
			this.header = document.querySelector(this.dataSelector('newHeader'));
			const colorVersions = ['lightColorVersion', 'veryLightColorVersion', 'vividColorVersion', 'darkColorVersion'];
			this.globalPrefix = 'tni-';
			this.headerPrefix = 'newHeader--';
			this.blockPrefix = 'block--';

			for(const color of colorVersions) {
				if(this.firstSection.classList.contains(this.globalPrefix + this.blockPrefix + color)) {
					this.classList(document.body).add(color);
					if(this.header) this.header.classList.add(this.globalPrefix + this.headerPrefix + color);
				} else {
					this.classList(document.body).remove(color);
					if(this.header) this.header.classList.remove(this.globalPrefix + this.headerPrefix + color);
				}
			}
		}
	}
}


export default DefaultPageTransition;
